import MaterialTable, { MaterialTableProps, MTableBodyRow as MTableRow } from 'material-table';
import React from 'react';
import { useHistory } from 'react-router';
import { Link, useLocation } from 'react-router-dom';

export type CreateActionProps<T> = {
  tooltip?: string;
  createNew: () => void;
};
export type TableProps<T extends object> = {
  tableId: string;
  entityname: string;
  emptyText?: string;
  title: string;
  onRowClick?: (value: T) => void;
  buildLink?: (value: T) => string;
  editRow?: (value: T) => void;
  create?: CreateActionProps<T>;
  deleteRow?: (value: T) => void;
  data: T[];
  columns: MaterialTableProps<T>['columns'];
};

function useQuery(): URLSearchParams {
  return new URLSearchParams(useLocation().search);
}

function getPageKey(tableId: string) {
  return `${tableId}_page`;
}

type TableQueryArguments = {
  page: number;
};

function extractQueryArguments(tableId: string, query: URLSearchParams): TableQueryArguments {
  const pageKey = getPageKey(tableId);
  const pageParam = query.get(pageKey);

  const page = (() => {
    if (!pageParam) return 0;
    try {
      const pageValue = parseInt(pageParam, 10);
      if (Number.isNaN(pageValue)) return 0;
      return pageValue;
    } catch (e) {
      return 0;
    }
  })();

  return {
    page,
  };
}

const RowLink = (props) => {
  return (
    <Link
      {...props}
      className={props.className + ' CustomRowRoot'}
      style={{
        textDecoration: 'none',
        transition: 'all 300ms ease 0s',
      }}
    />
  );
};

export function LocalizedTable<T extends object>(props: TableProps<T>) {
  const {
    entityname,
    title,
    onRowClick,
    data,
    columns,
    editRow,
    create,
    deleteRow,
    tableId,
    buildLink,
    emptyText,
  } = props;
  const query = useQuery();
  const location = useLocation();
  const history = useHistory();

  const { page } = extractQueryArguments(tableId, query);

  const actions: {
    icon: string;
    tooltip?: string;
    isFreeAction?: boolean;
    onClick: (e: any, data: T | T[]) => void;
  }[] = [];
  if (editRow) {
    actions.push({
      icon: 'create',
      tooltip: `${entityname} bearbeiten`,
      onClick: (event: any, data: T | T[]) => {
        if (isSingleEntity(data)) editRow(data);
      },
    });
  }

  const isSingleEntity = (data: T | T[]): data is T => {
    return (data as any).length === undefined;
  };

  if (deleteRow) {
    actions.push({
      icon: 'delete',
      tooltip: 'Gruppe löschen',
      onClick: (event: any, rowData: T | T[]) => {
        if (isSingleEntity(rowData)) deleteRow(rowData);
      },
    });
  }

  if (create) {
    actions.push({
      icon: 'add',
      tooltip: create.tooltip,
      isFreeAction: true,
      onClick: () => {
        create.createNew();
      },
    });
  }

  return (
    <MaterialTable
      components={{
        Row: (props) => {
          if (!buildLink) return <MTableRow {...props} />;

          const data = props.data as T;
          const link = buildLink(data);
          return (
            <MTableRow
              {...props}
              component={RowLink}
              to={link}
              style={{ textDecoration: 'none' }}
            />
          );
        },
      }}
      localization={{
        body: {
          emptyDataSourceMessage: emptyText ?? `Keine ${entityname} vorhanden`,
          editRow: {
            deleteText: 'Soll dieses Gruppe wirklich gelöscht werden?',
            cancelTooltip: 'Abbrechen',
            saveTooltip: 'Speichern',
          },
        },
        toolbar: {
          searchPlaceholder: 'Suche',
          searchTooltip: 'Suche',
        },
        pagination: {
          labelRowsPerPage: 'Zeilen pro Seite:',
          labelRowsSelect: 'Zeilen',
          labelDisplayedRows: '{from}-{to} von {count}',
          nextAriaLabel: 'Nächste Seite',
          previousTooltip: 'Vorherige Seite',
          nextTooltip: 'Nächste Seite',
          lastTooltip: 'Letzte Seite',
          firstTooltip: 'Erste Seite',
        },
        header: {
          actions: 'Aktionen',
        },
      }}
      options={{
        actionsColumnIndex: -1,
        pageSize: 10,
        initialPage: page,
      }}
      onChangePage={(page) => {
        const pageKey = getPageKey(tableId);
        query.set(pageKey, page.toString());

        const newQuery = query.toString();
        history.replace({
          pathname: location.pathname,
          search: newQuery,
        });
      }}
      onRowClick={
        onRowClick
          ? (e, entity?: T) => {
              if (entity) onRowClick(entity);
            }
          : undefined
      }
      title={title}
      actions={actions}
      columns={columns}
      data={data}
    />
  );
}
