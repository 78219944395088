import { Box, Button, CircularProgress, Grid, Paper, Toolbar } from '@material-ui/core';
import React from 'react';
import { FormActionError, SuccessAlert, UseSuccessAlert } from './feedback';

export type FormWrapperProps = {
  formTitle: string;
  submit: () => void;
  successAlert?: UseSuccessAlert & { message: string };
  actionError?: string;
  children?: React.ReactNode;
  canSubmit: boolean;
  isLoading: boolean;
  actionName: string;
};

export function FormWrapper(props: FormWrapperProps) {
  const {
    formTitle,
    submit,
    successAlert,
    actionError,
    children,
    canSubmit,
    isLoading,
    actionName,
  } = props;

  return (
    <Grid spacing={3} item xs={12} sm={12} md={11} lg={7}>
      <Grid item xs={12}>
        <Paper>
          <Toolbar>
            <Grid item container xs={12} justify="space-between" alignItems="center">
              <h1>{formTitle}</h1>
            </Grid>
          </Toolbar>

          <form
            onSubmit={(e) => {
              e.preventDefault();
              submit();
            }}
            onKeyPress={(event) => {
              if (event.which === 13 /* Enter */) {
                event.preventDefault();
              }
            }}
          >
            <Box p={2}>
              <Grid container spacing={2} direction="column">
                {children}
              </Grid>

              {actionError && <FormActionError message={actionError} />}

              <Box mt={2}>
                <Button variant="contained" color="primary" type="submit" disabled={!canSubmit}>
                  {isLoading ? (
                    <CircularProgress style={{ color: 'white' }} size={25} />
                  ) : (
                    actionName
                  )}
                </Button>
              </Box>
            </Box>
          </form>
        </Paper>
      </Grid>
      <SuccessAlert {...(successAlert ?? { open: false, handleClose: () => {}, message: '' })} />
    </Grid>
  );
}
