import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  TextField,
  Toolbar,
} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Editor } from '@tinymce/tinymce-react';
import { kDefaultMapPosition, kSupportedLanguages } from 'config';
import { Languages, Park } from 'data/entity/Park';
import { CreateVariables, ParkDetailsContainer, UpdateVariables } from 'model/parks/Detail';
import { getParkDetailsContainer } from 'Provider';
import React, { Component } from 'react';
import { useParams } from 'react-router-dom';
import { FormActionError, SuccessAlert, useSuccessAlert } from 'ui/components/form/feedback';
import { DisplayImage } from 'ui/components/form/image';
import { LocationSelection } from 'ui/components/forms/Location';
import { Subscribe } from 'unstated-typescript';

export function ParkDetailPage() {
  const container = getParkDetailsContainer();
  const params = useParams<{ parkId?: string }>();
  const { parkId } = params;

  if (parkId) container.load(parkId);
  return (
    <Subscribe to={[container]}>
      {(container) => {
        const {
          park: { isLoading, hasError, hasData, data },
        } = container.state;

        if (isLoading) return <CircularProgress />;

        if (hasError) return <p>Park konnte nicht geladen werden.</p>;

        if (hasData && !data) {
          return <p>Park existiert nicht.</p>;
        }

        return <ParkDetailForm container={container} park={data ?? undefined} />;
      }}
    </Subscribe>
  );
}

type ParkDetailFormProps = {
  park?: Park;
  container: ParkDetailsContainer;
};

type ParkDetailState = {
  errors: FormError[];
} & Omit<Park, 'id'>;

enum FormError {
  PositionMissing,
  LanguageMissing,
  PhonePreviewMissing,
  TabletPreviewMissing,
  DuplicateParkName,
}

function ParkDetailForm(props: ParkDetailFormProps) {
  const { park, container } = props;

  const isNewPark = !park;
  const formTitle = isNewPark ? 'Neuer Park' : 'Park bearbeiten';

  const [name, setName] = React.useState(park?.displayName ?? '');
  const [transitStopName, setTransitStopName] = React.useState(park?.transitStop ?? '');

  const [position, setPosition] = React.useState(park?.location);
  const [parkingLotPosition, setParkingLotPosition] = React.useState(park?.carDestination);

  const [previewImage, setPreviewImage] = React.useState<File | undefined | null>(undefined);
  const [previewImageUrl, setPreviewImageUrl] = React.useState(park?.titleImageUrl ?? null);
  const [previews, setPreviews] = React.useState(
    park?.previews ?? {
      de: '',
      en: '',
    },
  );
  const [historyTexts, setHistoryTexts] = React.useState<Record<Languages, string>>(
    park?.history ?? {
      de: '',
      en: '',
    },
  );

  const [successMessage, setSuccessMesage] = React.useState('');
  const successAlertProps = useSuccessAlert();

  const saveOperation = container.state.saveOperation;
  const { message: saveError } = saveOperation;

  const actionLoading = saveOperation.isLoading;
  const [error, setError] = React.useState<undefined | string>();

  const save = async (e) => {
    e.preventDefault();
    setError(undefined);

    if (!position) {
      setError('Parkstandort muss gesetzt sein.');
      return;
    }

    if (isNewPark) {
      const vars: CreateVariables = {
        appOrder: 5,
        displayName: name,
        history: historyTexts,
        previews,
        titleImage: previewImage,
        transitStop: transitStopName,
        location: position,
        carDestination: parkingLotPosition,
      };
      await container.create(vars);
    } else {
      const vars: UpdateVariables = {
        id: park!.id,
        appOrder: 5,
        displayName: name,
        history: historyTexts,
        previews,
        titleImage: previewImage,
        transitStop: transitStopName,
        location: position,
        carDestination: parkingLotPosition,
      };
      await container.update(vars);
    }

    const {
      saveOperation: { hasError, isFinished },
    } = container.state;
    if (hasError) {
      return;
    }

    const feedbackMessage = isNewPark ? 'Park erfolgreich erstellt.' : 'Park aktualisiert.';
    successAlertProps.showSuccess();
    setSuccessMesage(feedbackMessage);

    if (!isNewPark) return;

    if (isFinished) {
      const park = container.state.park.data!;

      const newUrl = `/park/${park.id}/details`;
      window.history.pushState('', 'Park bearbeiten', newUrl);
    }
  };

  const inputDisabled = false;

  const actionName = isNewPark ? 'Erstellen' : 'Speichern';

  const initialParkPosition = position ?? kDefaultMapPosition;
  const initialParkingLotPosition = parkingLotPosition ?? initialParkPosition;
  return (
    <Grid spacing={3} item xs={12} sm={12} md={11} lg={7}>
      <Grid item xs={12}>
        <Paper>
          <Toolbar>
            <h1>{formTitle}</h1>
          </Toolbar>

          <form onSubmit={save}>
            <Box p={2}>
              <Box p={2}>
                <TextField
                  fullWidth={true}
                  required={true}
                  disabled={inputDisabled}
                  id="outlined-basic"
                  label="Parkname"
                  variant="outlined"
                  value={name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Box>
              <Box p={2}>
                <Grid spacing={3} container>
                  <Grid item xs={12}>
                    <TextField
                      required={false}
                      fullWidth={true}
                      disabled={inputDisabled}
                      id="outlined-basic"
                      label="Haltestelle"
                      helperText="Name der Haltestelle, die Benutzern bei Busanreise angezeigt wird "
                      variant="outlined"
                      value={transitStopName}
                      onChange={(e) => {
                        setTransitStopName(e.target.value);
                      }}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Box p={2}>
                <Grid item xs={12}>
                  <h2>Parkstandort</h2>
                  <p>
                    Der gewählte Standort wird Benutzern bei Ansicht der Parkanlage zuerst
                    angezeigt.
                  </p>
                  <LocationSelection
                    onChange={(position) => {
                      setPosition(position);
                    }}
                    height={400}
                    initialPosition={initialParkPosition}
                  />
                </Grid>
              </Box>
              <Box p={2}>
                <Grid item xs={12}>
                  <h2>Parkplatz</h2>

                  <LocationSelection
                    onChange={(position) => {
                      setParkingLotPosition(position);
                    }}
                    height={400}
                    initialPosition={initialParkingLotPosition}
                  />
                </Grid>
              </Box>{' '}
              <Box>
                <h2>Vorschaubild</h2>
                <DisplayImage
                  url={previewImageUrl}
                  onChange={(file, isValid, url) => {
                    setPreviewImage(file);
                    setPreviewImageUrl(url ?? null);
                  }}
                />
              </Box>
              <Box>
                <ParkPreviewTexts
                  supportedLanguages={kSupportedLanguages}
                  previews={previews}
                  history={historyTexts}
                  onPreviewChange={setPreviews}
                  onHistoryChange={setHistoryTexts}
                />
              </Box>
              {(saveError || error) && <FormActionError message={saveError ?? error!!} />}
              <Box mt={2}>
                <Button variant="contained" color="primary" type="submit" disabled={inputDisabled}>
                  {actionLoading ? (
                    <CircularProgress style={{ color: 'white' }} size={25} />
                  ) : (
                    actionName
                  )}
                </Button>
              </Box>
            </Box>
          </form>
        </Paper>
      </Grid>
      <SuccessAlert {...successAlertProps} message={successMessage} />
    </Grid>
  );
}

enum ParkStatus {
  private,
  public,
}
type ParkStatusProps = {
  status: ParkStatus;
};
function ParkStatusSelection(props: ParkStatusProps) {
  const { status: propsStatus } = props;

  const [status, setStatus] = React.useState(propsStatus);

  return (
    <div>
      {' '}
      <FormControlLabel
        control={
          <Switch
            checked={status === ParkStatus.public}
            onChange={(v) =>
              setStatus(status === ParkStatus.public ? ParkStatus.private : ParkStatus.public)
            }
            name="checkedB"
            color="primary"
          />
        }
        label="Park veröffentlichen"
      />
      <p>
        {status === ParkStatus.public
          ? 'Der Park wird in der App für alle Benutzer zum Kauf zur Verfügung gestellt.'
          : 'Der Park ist nur in der HistoryGo Partner App sichtbar.'}
      </p>
    </div>
  );
}

type ParkPreviewTextProps = {
  supportedLanguages: string[];
  previews: Record<string, string>;
  history: Record<string, string>;
  onPreviewChange: (texts: Record<string, string>) => void;
  onHistoryChange: (texts: Record<string, string>) => void;
};

type ParkPreviewTextState = {
  supportedLanguages: string[];
  previews: Record<string, string>;
  history: Record<string, string>;
  activeLanguage: string;
};
class ParkPreviewTexts extends Component<ParkPreviewTextProps, ParkPreviewTextState> {
  constructor(props: ParkPreviewTextProps) {
    super(props);
    const { supportedLanguages, previews, history } = props;

    this.state = {
      supportedLanguages,
      previews,
      history,

      activeLanguage: 'de',
    };
  }

  getLanguageName = (code: string): string => {
    switch (code) {
      case 'en':
        return 'Englisch';
      default:
        return 'Deutsch';
    }
  };

  changeLanguage = (code) => {
    this.setState({
      activeLanguage: code,
    });
  };

  setPreviewText = (value) => {
    const previews = { ...this.state.previews, [this.state.activeLanguage]: value };
    this.props.onPreviewChange(previews);
    this.setState({
      ...this.state,
      previews,
    });
  };

  setHistoryText = (value) => {
    const history = { ...this.state.history, [this.state.activeLanguage]: value };
    this.props.onHistoryChange(history);
    this.setState({
      ...this.state,
      history,
    });
  };

  render() {
    const { activeLanguage, supportedLanguages } = this.state;

    const previewText = this.state.previews[activeLanguage] ?? '';
    const historyText = this.state.history[activeLanguage] ?? '';

    return (
      <Box p={2}>
        <Grid>
          <Box pb={2}>
            <Grid container direction="row" justify="flex-end" alignItems="flex-start">
              <FormControl variant="outlined">
                <InputLabel id="demo-simple-select-outlined-label">Sprache</InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={activeLanguage}
                  onChange={(e) => this.changeLanguage(e.target.value)}
                  label="Sprache"
                >
                  {supportedLanguages.map((languageCode) => {
                    const name = this.getLanguageName(languageCode);
                    return <MenuItem value={languageCode}>{name}</MenuItem>;
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Box>
          <Box>
            <h3 style={{ textAlign: 'left', margin: '0' }}>Vorschautext</h3>
            <TextField
              fullWidth={true}
              multiline={true}
              variant="outlined"
              value={previewText}
              onChange={(e) => this.setPreviewText(e.target.value)}
              inputProps={{ maxLength: 180 }}
              rows={3}
              rowsMax={5}
              placeholder="Kurze Beschreibung der Parkgeschichte"
              helperText={'Inhalt wird in Parkvorschau angezeigt. (' + previewText.length + '/180)'}
            />
          </Box>
          <Box mt={4}>
            <h3 style={{ textAlign: 'left', margin: '0' }}>Geschichte</h3>
            <Editor
              value={historyText}
              apiKey="wrgx1ffz0ild9ho5ww1u4ir7mkyysg1w2uvemg9921k8sxkr"
              initialValue={historyText}
              init={{
                height: 380,
                menubar: false,
                resize: false,
                entity_encoding: 'raw',
                plugins: [
                  'advlist autolink lists link image charmap print preview anchor',
                  'searchreplace visualblocks code fullscreen',
                  'insertdatetime media table paste code help wordcount',
                ],
                toolbar: 'undo redo | formatselect | bold italic ',
              }}
              onEditorChange={(a) => {
                this.setHistoryText(a);
              }}
            />
          </Box>
        </Grid>
      </Box>
    );
  }
}
