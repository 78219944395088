import React from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import { IconButton } from '@material-ui/core';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { FormInputError } from './feedback';

export type Dimension = { width: number; height: number };

export type DisplayImageProps = {
  url?: string | null;
  onChange: (file: File | null, isValid: boolean, imageUrl?: string) => void;
  disabled?: boolean;
  maxSize?: number;
  // width/height
  aspectRatio?: number;
  minDimensions?: Partial<Dimension>;
  maxDimensions?: Partial<Dimension>;
};
export function DisplayImage(props: DisplayImageProps) {
  const { onChange, minDimensions, maxDimensions, aspectRatio, maxSize } = props;
  const [image, setImage] = React.useState<string | undefined | null>(props.url);
  const [errors, setErrors] = React.useState<string[]>([]);
  const disabled = props.disabled ?? false;

  const onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      const [file] = event.target.files;
      let img = file;
      setErrors([]);
      const errors: string[] = [];
      if (maxSize && file.size > maxSize) {
        errors.push(`Datei überschreitet maximale Größe von ${(maxSize / 1024).toFixed(0)}KB.`);
      }

      const url = URL.createObjectURL(img);
      setImage(url);

      if (errors.length > 0) setErrors(errors);
      const isValid = errors.length === 0;
      onChange(event.target.files[0], isValid, url);

      // const imageObject = new window.Image();
      // imageObject.onload = () => {
      //   const width = imageObject.naturalWidth;
      //   const height = imageObject.naturalHeight;

      //   const imageAspectRatio = width / height;

      //   if (aspectRatio) {
      //     const friendlyFormat = aspectRatio.toFixed(2).toString().replaceAll('.', ',');
      //     const expectedWidth = Math.floor(aspectRatio * height);
      //     const matchesAspect = expectedWidth === width;
      //     if (!matchesAspect)
      //       errors.push(
      //         `Bildverhältnisse entsprechen nicht dem Format ${friendlyFormat}, Bildbreite von ${expectedWidth}px erwartet.`,
      //       );
      //   }

      //   if (minDimensions) {
      //     const { height: minHeight, width: minWidth } = minDimensions;
      //     if (minHeight && height < minHeight) {
      //       errors.push(`Das Bild muss eine Höhe von mindestens ${minHeight}px haben.`);
      //     }

      //     if (minWidth && width < minWidth) {
      //       errors.push(`Das Bild muss eine Breite von mindestens ${minWidth}px haben.`);
      //     }
      //   }

      //   if (maxDimensions) {
      //     const { height: maxHeight, width: maxWidth } = maxDimensions;
      //     if (maxHeight && height > maxHeight) {
      //       errors.push(`Die Höhe des Bildes muss kleiner als ${maxHeight}px sein.`);
      //     }

      //     if (maxWidth && width > maxWidth) {
      //       errors.push(`Die Breite des Bildes muss kleiner als ${maxWidth}px sein.`);
      //     }
      //   }

      //   if (errors.length > 0) setErrors(errors);
      //   else onChange(event.target.files[0], url);
      //   URL.revokeObjectURL(file);
      // };
      // imageObject.src = url;
    }
  };

  const removeImage = () => {
    setImage(undefined);
    onChange(null, true);
  };

  if (disabled && !image) return <p>Kein Bild vorhanden</p>;

  return (
    <div>
      <div>
        {image && (
          <div style={{ position: 'relative', maxWidth: '100%' }}>
            <img src={image} style={{ height: '400px', width: 'auto', maxWidth: '100%' }} />
            <div style={{ position: 'absolute', top: 0, right: 0 }}>
              <IconButton aria-label="delete" onClick={() => removeImage()}>
                <DeleteIcon />
              </IconButton>
            </div>
            <div style={{ textAlign: 'left' }}>
              {errors.length > 0 && (
                <div>
                  {errors.map((error) => (
                    <FormInputError message={error} />
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        {!image && (
          <div>
            <h3>Bild auswählen</h3>
            <label htmlFor="myImage">
              <IconButton color="primary" aria-label="upload picture" component="span">
                <PhotoCamera />
              </IconButton>
            </label>

            {minDimensions && (
              <p>{`min: ${minDimensions.width ?? '-'}x${
                minDimensions.height ?? '-'
              } (Breite x Höhe)`}</p>
            )}
            {maxDimensions && (
              <p>{`max: ${maxDimensions.width ?? '-'}x${
                maxDimensions.height ?? '-'
              } (Breite x Höhe)`}</p>
            )}

            <input
              type="file"
              id="myImage"
              accept="image/png, image/jpeg, image/jpg"
              style={{ display: 'none' }}
              name="myImage"
              onChange={onImageChange}
            />
          </div>
        )}
      </div>
    </div>
  );
}
