import { Button, Grid, TextField } from '@material-ui/core';
import { InfoAction } from 'data/entity/Info';
import React, { useEffect, useRef } from 'react';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import AlertDialog from '../form/AlertDialog';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { LanguageSelection } from '../form/language';
import { Languages } from 'data/entity/Park';
import Dropzone, { useDropzone } from 'react-dropzone';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import { InfoActionInput as InfoActionInputModel } from 'model/info/Detail';
import EditIcon from '@material-ui/icons/Edit';
import { kInfoActionMaxFileSize } from 'config';
import { FormInputError } from '../form/feedback';

export type InfoActionProps = {
  disabled?: boolean;
  item: InfoActionInputModel;
  onDelete?: () => void;
  onChange?: (info: InfoActionInputModel) => void;
  validate?: boolean;
};

function extractFilenames(urls: InfoAction['urls']): Record<string, string> {
  if (!urls) return {};

  return Object.keys(urls).reduce((prev, cur) => {
    const val = urls[cur] as string | undefined;
    if (!val) return cur;

    const raw = val.startsWith('http') ? decodeURI(val.replaceAll('%2F', '/')) : val;

    const parts = raw.split('/');
    const part = parts[parts.length - 1];
    const name = part.split('?')[0];

    return {
      ...prev,
      [cur]: name,
    };
  }, {});
}

export function InfoActionInput(props: InfoActionProps) {
  const disabled = props.disabled ?? false;
  const { item, onDelete } = props;

  const validate = props.validate ?? false;
  const canDelete = onDelete && !disabled;

  const inputFile = useRef(null);
  const [name, setName] = React.useState(item.name);
  const [label, setLabel] = React.useState(item.label);
  const [urls, setUrls] = React.useState(item.urls ?? {});
  const [files, setFiles] = React.useState<Partial<Record<Languages, File>>>({});

  const [filenames, setFilenames] = React.useState(extractFilenames(item.urls));

  const [fileSizeError, setFileSizeError] = React.useState(false);

  const [language, setLanguage] = React.useState<Languages>('de');

  useEffect(() => {
    // Update the document title using the browser API    document.title = `You clicked ${count} times`;  });
    if (props.onChange) props.onChange({ ...item, name, label, urls, files });
  }, [name, label, files, urls]);

  const currentFileUrl = urls[language];

  const currentFile = files[language];

  const onFileChange = (file) => {
    const newFiles = { ...files, [language]: file };
    setFiles(newFiles);
    setFilenames({ ...filenames, [language]: file.name });

    const reader = new FileReader();

    reader.addEventListener(
      'load',
      function () {
        // convert image file to base64 string
        setUrls({ ...urls, [language]: reader.result });
      },
      false,
    );

    reader.readAsDataURL(file);
  };

  const getNameError = (): string | undefined => {
    if (!validate) return undefined;

    const empty = name.trim() === '';

    return empty ? 'Name benötigt' : undefined;
  };

  const getLabelError = (): string | undefined => {
    if (!validate) return undefined;

    const germanLabel = label['de'];
    const empty = germanLabel === undefined || germanLabel.trim() === '';

    return empty ? 'Deutsche Bezeichnung benötigt' : undefined;
  };

  const nameError = getNameError();
  const labelError = getLabelError();

  const requiresGermanFile = validate && urls['de'] === undefined && files['de'] === undefined;

  const fileEditId = `file-selection-edit-${language}`;

  return (
    <Grid xs={12} item container>
      <Paper>
        <Box p={2}>
          <Grid container xs={12} spacing={3}>
            <Grid container item xs={12} justify="space-between">
              <TextField
                onChange={(e) => setName(e.target.value)}
                value={name}
                disabled={disabled}
                label="Name"
                helperText={nameError ?? 'Interner Name, nicht in der App sichtbar'}
                error={nameError !== undefined}
              />
              {canDelete && (
                <AlertDialog
                  title="Aktion löschen"
                  positiveAction="Löschen"
                  contentText={`Soll ${name} wirklich gelöscht werden?`}
                  action={async () => {
                    onDelete!();
                    return { success: true };
                  }}
                  builder={(setOpen) => {
                    return (
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          setOpen(true);
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    );
                  }}
                />
              )}
            </Grid>

            <Grid item xs={12} container justify="space-between">
              <TextField
                disabled={disabled}
                value={label[language] ?? ''}
                onChange={(e) => {
                  setLabel({ ...label, [language]: e.target.value });
                }}
                label="Bezeichnung"
                helperText={labelError ?? 'Text der im Aktionsbutton angezeigt wird'}
                error={labelError !== undefined}
              />
              <LanguageSelection
                current={language}
                onChange={(language) => setLanguage(language)}
              />

              <Grid item xs={12}>
                <Grid xs={12}>
                  <h4 style={{ marginBottom: 6, textAlign: 'left' }}>Datei</h4>
                </Grid>
                <Grid xs={12}>
                  <p style={{ textAlign: 'left', marginBottom: 8 }}>
                    Datei die beim Klick auf den Button geöffnet wird.
                  </p>
                </Grid>
                {!currentFileUrl && !currentFile && (
                  <Dropzone
                    accept={['image/png', 'image/jpeg', 'image/jpg', 'application/pdf']}
                    maxFiles={1}
                    maxSize={kInfoActionMaxFileSize}
                    disabled={disabled}
                    onDrop={(acceptedFiles, rejectedFiles) => {
                      if (fileSizeError) setFileSizeError(true);
                      if (acceptedFiles && acceptedFiles[0]) {
                        onFileChange(acceptedFiles[0]);
                      }

                      if (rejectedFiles && rejectedFiles.length > 0) {
                        setFileSizeError(true);
                      }
                    }}
                  >
                    {({ getRootProps, getInputProps }) => (
                      <>
                        <section
                          style={{
                            border: requiresGermanFile ? '1px solid red' : '1px solid gray',
                            borderRadius: '8px',

                            cursor: 'default',
                          }}
                        >
                          <div {...getRootProps()} style={{ padding: '16px' }}>
                            <input {...getInputProps()} />
                            <InsertDriveFileIcon />
                            <p style={{ margin: 0 }}>Hier klicken oder Datei ablegen</p>
                            <p
                              style={{
                                color: 'grey',
                                margin: 0,
                                fontSize: '12px',
                              }}
                            >
                              PNG, JPEG, PDF, max. 6MB
                            </p>
                          </div>
                        </section>
                        {fileSizeError && (
                          <Box pt={2}>
                            <FormInputError message="Dateigröße überschreitet Limit" />
                          </Box>
                        )}
                      </>
                    )}
                  </Dropzone>
                )}
                {(currentFileUrl || currentFile) && (
                  <div>
                    <div>
                      <input
                        ref={inputFile}
                        accept="image/jpeg,image/png,image/jpg,application/pdf"
                        style={{ display: 'none' }}
                        id={fileEditId}
                        type="file"
                        onChange={(e) => {
                          if (!e.target.files) return;

                          const file = e.target.files[0];
                          const { size: fileSize } = file;
                          console.log(file.size);
                          if (fileSize >= kInfoActionMaxFileSize) {
                            console.log('exceeds size');
                            setFileSizeError(true);
                            return;
                          }

                          if (fileSizeError) setFileSizeError(false);

                          onFileChange(file);
                        }}
                      />

                      <a href={urls[language]} target="_blank">
                        {filenames[language]}
                      </a>

                      {!disabled && (
                        <>
                          <IconButton
                            onClick={() => {
                              //@ts-ignore
                              document.getElementById(fileEditId)?.click();
                            }}
                          >
                            <EditIcon />
                          </IconButton>

                          <IconButton
                            onClick={() => {
                              const copy = { ...filenames };
                              delete copy[language];
                              setFileSizeError(false);
                              setFilenames(copy);

                              const urlCopy = { ...urls };
                              delete urlCopy[language];
                              setUrls(urlCopy);

                              const fileCopy = { ...files };
                              delete fileCopy[language];
                              setFiles(fileCopy);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </>
                      )}
                    </div>
                    {fileSizeError && (
                      <Box pt={1}>
                        <FormInputError message="Dateigröße überschreitet Limit" />
                      </Box>
                    )}
                  </div>
                )}
                {requiresGermanFile && (
                  <p style={{ color: 'red', textAlign: 'left' }}>
                    Datei in deutscher Sprache erforderlich
                  </p>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Grid>
  );
}
