import { CircularProgress, Grid } from '@material-ui/core';
import { InfoMembership } from 'data/entity/Membership';
import { ParkMemberContainer } from 'model/parks/Member';
import { getParkMemberContainer } from 'Provider';
import React from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { LocalizedTable } from 'ui/components/Table';
import { Subscribe } from 'unstated-typescript';

export function MemberOverviewPage() {
  const params = useParams<{ parkId: string }>();
  const history = useHistory();
  const parkId = params.parkId;

  const container = getParkMemberContainer(parkId);

  return (
    <Subscribe to={[container]}>
      {(container: ParkMemberContainer) => {
        const { entities: parkMemberRes, infoMemberships } = container.state;
        const { isIdle, isLoading, hasError, message, data } = parkMemberRes;
        if (isIdle) {
          container.load();
        }

        if (infoMemberships.isIdle) {
          container.loadInfoMemberships();
        }

        if (isIdle || isLoading) return <CircularProgress />;

        if (hasError) return <p>{message}</p>;

        const members = data ?? [];

        const infoMembers = infoMemberships.data ?? [];

        return (
          <Grid item xs={12} container spacing={3}>
            <Grid item xs={12}>
              <LocalizedTable
                tableId="members"
                entityname="Mitglied"
                title="Mitglieder"
                columns={[
                  {
                    title: 'E-Mail',
                    field: 'user.email',
                    type: 'string',
                  },
                  {
                    title: 'Admin',
                    type: 'boolean',
                    field: 'admin',
                  },
                  {
                    title: 'Bearbeiter',
                    type: 'boolean',
                    field: 'write',
                  },
                  {
                    title: 'Betrachter',
                    type: 'boolean',
                    field: 'read',
                  },
                ]}
                data={members}
              />
            </Grid>
            <Grid item xs={12}>
              <LocalizedTable
                tableId="guest"
                entityname="Mitgliedschaft"
                emptyText="Keine Gastzugänge vorhanden"
                title="Gastzugänge"
                editRow={(membership) => {
                  const url = '/park/' + parkId + '/membership/' + membership.id;
                  history.push(url);
                }}
                create={{
                  createNew: () => {
                    const url = '/park/' + parkId + '/membership?type=info';
                    history.push(url);
                  },
                  tooltip: 'Zugang erstellen',
                }}
                columns={[
                  {
                    title: 'E-Mail',
                    field: 'user.email',
                    type: 'string',
                  },
                  {
                    title: 'Info',
                    type: 'string',

                    render: (ms: InfoMembership) => {
                      if (!ms.info?.title) return '-';

                      return <Link to={`/park/${parkId}/infos/${ms.infoId}`}>{ms.info.title}</Link>;
                    },
                  },
                  {
                    title: 'Hinzugefügt',
                    type: 'date',
                    field: 'createdAt',
                  },
                ]}
                data={infoMembers}
              />
            </Grid>
          </Grid>
        );
      }}
    </Subscribe>
  );
}
