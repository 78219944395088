import { Info } from 'data/entity/Info';
import { CreateInfoVariables, InfoRepository, UpdateInfoVariables } from 'data/repositories/Info';

type CacheEntry = { timestamp: Date; data: Info | null };

export class InfoStore implements InfoRepository {
  private cache: Record<string, CacheEntry> = {};

  public constructor(private repository: InfoRepository) {}

  async create(vars: CreateInfoVariables): Promise<Info> {
    const createdInfo = await this.repository.create(vars);
    this.cache[createdInfo.id] = { timestamp: new Date(), data: createdInfo };
    return createdInfo;
  }

  async update(vars: UpdateInfoVariables): Promise<Info> {
    const info = await this.repository.update(vars);
    this.cache[info.id] = { timestamp: new Date(), data: info };
    return info;
  }

  generateID(): string {
    return this.repository.generateID();
  }

  getParkInfos(parkId: string): Promise<Info[]> {
    return this.repository.getParkInfos(parkId);
  }

  getInfoByIds(infoIds: string[]): Promise<Info[]> {
    return this.repository.getInfoByIds(infoIds);
  }

  async delete(id: string): Promise<void> {
    await this.repository.delete(id);
    if (this.cache[id]) delete this.cache.id;
  }

  async get(id: string): Promise<Info | null> {
    const cached = this.cache[id];
    if (cached) {
      const timeSinceCaching = new Date().getTime() - cached.timestamp.getTime();
      if (timeSinceCaching < 1000 * 3) {
        return cached.data;
      }
    }

    const info = await this.repository.get(id);
    this.cache[id] = { timestamp: new Date(), data: info };
    return info;
  }
}
