import { Box, Paper } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { Info, mapToMapIcon } from 'data/entity/Info';
import { GpsPosition } from 'data/entity/Park';
import { Map } from 'ol';
import { Vector as VectorLayer } from 'ol/layer';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { MapProps, OLMap } from './Map';

export type Marker = {
  id?: string;
  icon?: string;
} & GpsPosition;

export type LocationSelectionProps = {
  onChange: (value?: GpsPosition) => void;
  initialPosition?: MapProps['initialPosition'];
  initialCameraPosition?: GpsPosition;
  value?: GpsPosition;
  height?: number;
  disabled?: boolean;
};

type State = {
  position?: GpsPosition;
};
export class LocationSelection extends React.Component<LocationSelectionProps, State> {
  private map: Map;
  private vectorLayer: VectorLayer;

  constructor(props) {
    super(props);
    this.state = {
      position: this.props.initialPosition,
    };
  }

  componentDidUpdate(prevProps: LocationSelectionProps) {
    if (prevProps.initialPosition !== this.props.initialPosition) {
      this.setState({
        ...this.state,
        position: this.props.initialPosition,
      });
    }
  }

  render() {
    const position = this.state.position;

    const disabled = this.props.disabled ?? false;

    const markers = position ? [{ lng: position.lng, lat: position.lat }] : [];
    return (
      <Grid container>
        <Grid item xs={12}>
          <OLMap
            height={this.props.height}
            initialPosition={this.props.initialPosition ?? this.props.initialCameraPosition!}
            onClick={(p) => {
              if (disabled) return;
              const position = p ? { ...p } : undefined;
              this.setState({
                ...this.state,
                position,
              });
              this.props.onChange(position);
            }}
            onMarkerSelected={(p) => {}}
            markers={markers}
          />
        </Grid>
      </Grid>
    );
  }
}

export type InfoMapProps = {
  initial: GpsPosition;
  infos: Info[];
};
export function InfoMap(props: InfoMapProps) {
  const params = useParams<{ parkId: string }>();

  const { initial, infos } = props;
  const markers = infos.map(({ position, id, infoType }) => {
    return { lng: position.lng, lat: position.lat, id, icon: mapToMapIcon(infoType) };
  });

  const [position, setPosition] = React.useState<{ x: number; y: number } | undefined>(undefined);
  const [infoPopupVisisble, setInfoPopupVisible] = React.useState(false);
  const [hoveredInfo, setHoveredInfo] = React.useState<Info | undefined>(undefined);

  return (
    <Grid container>
      <Grid item xs={12}>
        <div
          style={{
            position: 'relative',
            zIndex: 2,
          }}
        >
          <OLMap
            height={600}
            initialPosition={initial}
            onHoverExit={() => {
              setHoveredInfo(undefined);

              setInfoPopupVisible(false);
            }}
            onHover={(marker, screenPosition) => {
              const info = infos.find((i) => i.id === marker.id);
              if (!info) return;

              setHoveredInfo(info);

              setPosition(screenPosition);
              setInfoPopupVisible(true);
            }}
            onMarkerSelected={(marker) => {
              const { id } = marker;
              if (!id) return;

              const info = infos.find((i) => i.id === id);
              if (!info) return;
            }}
            markers={markers}
          />
          {position && hoveredInfo && (
            <div style={{ left: position.x + 'px', top: position.y + 'px', position: 'absolute' }}>
              <Paper>
                <Box p={2}>
                  <p style={{ margin: 0 }}>{hoveredInfo.title}</p>
                  <Link
                    to={`/parks/${params.parkId}/infos/${hoveredInfo.id}`}
                    target="_blank"
                    onClick={(event) => {
                      event.preventDefault();
                      window.open(`/park/${params.parkId}/infos/${hoveredInfo.id}`);
                    }}
                  >
                    Details
                  </Link>
                </Box>
              </Paper>
            </div>
          )}
        </div>
      </Grid>
    </Grid>
  );
}
