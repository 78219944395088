import { Box, Chip, makeStyles, TextField } from '@material-ui/core';
import React from 'react';

export type TagsInputProps = {
  tags: string[];
  disabled?: boolean;
  hideText?: boolean;
  onChange: (value: string[]) => void;
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'start',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
}));

export function TagsInput(props: TagsInputProps) {
  const { tags, onChange } = props;
  const disabled = props.disabled ?? false;
  const hideText = props.hideText ?? false;

  const [tag, setTag] = React.useState('');
  const [error, setError] = React.useState<string | undefined>();

  const classes = useStyles();

  return (
    <Box>
      {!hideText && (
        <TextField
          helperText="Enter zum bestätigen"
          placeholder="Tag..."
          fullWidth={true}
          disabled={disabled}
          variant="outlined"
          value={tag}
          label={error}
          error={error !== undefined}
          onChange={(e) => {
            if (e.target.value.trim() === '') return;

            if (e.target.value.trim() !== '') setError(undefined);
            setTag(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              if (tag.trim() !== '') {
                const newTags = [...tags, tag];
                setTag('');
                onChange(newTags);
              } else {
                setError('Wert darf nicht leer sein.');
              }
            }
          }}
        />
      )}
      <Box className={classes.root}>
        {tags.map((tag) => (
          <Tag
            value={tag}
            disabled={disabled}
            onDelete={() => {
              const newTags = tags.filter((t) => t !== tag);
              onChange(newTags);
            }}
          />
        ))}
      </Box>
    </Box>
  );
}

function Tag(props: { value: string; disabled?: boolean; onDelete: () => void }) {
  const { value, disabled, onDelete } = props;
  return (
    <Chip disabled={disabled ?? false} label={value} onDelete={disabled ? undefined : onDelete} />
  );
}
