import { isParkMembership, Membership } from 'data/entity/Membership';
import { MembershipRepository } from 'data/repositories/Membership';
import logger from 'services/Logging';
import { Container } from 'unstated-typescript';
import { Operation, Resource } from '../data/Resource';
import IAuthenticationService from '../data/services/Authentication';
import { User } from '../data/user/User';

export type UserState = {
  user: Resource<User>;
  setup: Operation;
  logout: Operation;
  hasMembership: Resource<boolean>;
  memberships: Resource<Membership[]>;
};

export class UserContainer extends Container<UserState> {
  private authService: IAuthenticationService;

  constructor(
    authService: IAuthenticationService,
    private membershipRepository: MembershipRepository,
  ) {
    super();
    this.authService = authService;
    this.restoreSession();
  }

  state = {
    user: Resource.idle<User>(),
    setup: Operation.idle(),
    logout: Operation.idle(),
    hasMembership: Resource.idle<boolean>(),
    memberships: Resource.idle<Membership[]>(),
  };

  private async restoreSession() {
    this.setState({ setup: Operation.loading() });
    try {
      const user = await this.authService.restoreSession();
      if (user == null) {
        this.setState({ user: Resource.idle(), setup: Operation.success() });
        return;
      }

      const memberships = await this.membershipRepository.getUserMemberships(user.id);
      const hasMembership = memberships.length > 0;

      this.setState({
        user: Resource.success(user),
        setup: Operation.success(),
        hasMembership: Resource.success(hasMembership),
        memberships: Resource.success(memberships),
      });
    } catch (e) {
      logger.logError(e);
      this.setState({
        setup: Operation.error('Unable to restore user session.'),
      });
    }
  }

  async logout(): Promise<void> {
    this.setState({ ...this.state, logout: Operation.idle() });
    try {
      await this.authService.logout();
      this.setState({
        ...this.state,
        hasMembership: Resource.idle(),
        logout: Operation.success(),
        user: Resource.idle(),
        memberships: Resource.idle(),
      });
    } catch (e) {
      logger.logError(e);
      this.setState({ ...this.state, logout: Operation.error('Fehler beim Logout...') });
    }
  }

  async login(email: string, password: string): Promise<boolean> {
    this.setState({ user: Resource.loading() });
    try {
      const user = await this.authService.login(email, password);
      if (!user) {
        this.setState({ user: Resource.error('Nicht authorisiert.') });
        return false;
      }

      this.setState({
        ...this.state,
        hasMembership: Resource.loading(),
      });

      const memberships = await this.membershipRepository.getUserMemberships(user.id);
      const hasMembership = memberships.length > 0;

      this.setState({
        ...this.state,
        user: Resource.success(user),
        hasMembership: Resource.success(hasMembership),
        memberships: Resource.success(memberships),
      });

      return hasMembership;
    } catch (e) {
      logger.logError(e);
      let error = 'Unbekannter Fehler aufgetreten.';
      const { code } = e;
      if (code) {
        if (code === 'auth/wrong-password') {
          error = 'Falsche E-Mail oder falsches Passwort.';
        }
      }

      this.setState({ user: Resource.error(error) });
    }
    return false;
  }

  get isAuthenticated(): boolean {
    return this.state.user.hasData;
  }

  get isAuthorized(): boolean {
    return (
      this.isAuthenticated &&
      this.state.user.data != null &&
      this.state.hasMembership.hasData &&
      this.state.hasMembership.data === true
    );
  }

  get hasParkMembership(): boolean {
    const memberships = this.state.memberships.data ?? [];
    return memberships.some(isParkMembership);
  }
}
