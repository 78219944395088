import {
  InfoMembership,
  isInfoMembership,
  isParkMembership,
  Membership,
  ParkMembership,
  UserMembership,
} from 'data/entity/Membership';
import {
  CreateInfoMembershipVars,
  CreateMembershipVars,
  MembershipRelations,
  MembershipRepository,
  UpdateInfoMembershipVars,
  UpdateMembershipVars,
} from 'data/repositories/Membership';
import firebase from 'firebase';
import { FirestoreInfoRepository } from './Info';
import { getUser, membershipCollection } from './shared';

const collection = membershipCollection;

const isCreateInfoMembershipVars = (
  vars: CreateMembershipVars,
): vars is CreateInfoMembershipVars => {
  return (vars as { infoId?: string })?.infoId !== undefined;
};

const isUpdateInfoMembershipVars = (
  vars: UpdateMembershipVars,
): vars is UpdateInfoMembershipVars => {
  return (vars as UpdateInfoMembershipVars | undefined)?.permissions !== undefined;
};

export class FirestoreMembershipRepository implements MembershipRepository {
  public constructor(private firestore: firebase.firestore.Firestore) {}

  findById(membershipId: string): Promise<Membership | undefined> {
    return firebase
      .firestore()
      .collection('memberships')
      .doc(membershipId)
      .get()
      .then((d) => (!d.exists ? undefined : this.parseMembership(d)));
  }

  public async create(vars: CreateMembershipVars): Promise<Membership> {
    const userId = firebase.auth().currentUser!.uid;

    if (isCreateInfoMembershipVars(vars)) {
      const info = await new FirestoreInfoRepository().get(vars.infoId);
      if (!info) throw "Info doesn't exist.";
      const doc = firebase.firestore().collection('memberships').doc();
      const args = {
        ...vars,
        parkId: info.parkId ?? 'sanssouci',
        createdBy: userId,
        createdAt: new Date(),
        type: 'InfoMembership',
      };
      await doc.set(args);
      const membership: InfoMembership = {
        id: doc.id,
        ...args,
      };

      return membership;
    }
    throw 'Unknown create variables.';
  }

  public async update(vars: UpdateInfoMembershipVars): Promise<void> {
    if (isUpdateInfoMembershipVars(vars)) {
      const doc = firebase.firestore().collection('memberships').doc(vars.membershipId);
      await doc.update({
        permissions: vars.permissions,
        updatedAt: new Date(),
        updatedBy: firebase.auth().currentUser!.uid,
      });
    }
  }

  delete(membershipId: string): Promise<void> {
    return firebase.firestore().collection('memberships').doc(membershipId).delete();
  }

  public async getParkInfoMemberships(
    parkId: string,
    relations?: MembershipRelations[],
  ): Promise<InfoMembership[]> {
    const infoRepository = new FirestoreInfoRepository();

    const memberships = await this.firestore
      .collection(collection)
      .where('parkId', '==', parkId)
      .get()
      .then((snapshots) => {
        const { docs } = snapshots;
        return docs
          .map(this.parseMembership)
          .filter((d) => isInfoMembership(d)) as InfoMembership[];
      });

    if (relations) {
      return Promise.all(
        memberships.map(async (membership) => {
          const user = relations.includes(MembershipRelations.User)
            ? await getUser(membership.userId)
            : undefined;
          const info = relations.includes(MembershipRelations.Info)
            ? await infoRepository.get(membership.infoId)
            : undefined;

          return {
            ...membership,
            user: user ?? undefined,
            info: info ?? undefined,
          };
        }),
      );
    }

    return memberships;
  }

  getParkMember(parkId: string): Promise<UserMembership[]> {
    return this.getParkMemberships(parkId).then(async (memberships) => {
      return Promise.all(
        memberships.map(async (ms) => {
          const user = await getUser(ms.userId);
          return {
            ...ms,
            user: user!,
          };
        }),
      );
    });
  }

  hasMembership(userId: string): Promise<boolean> {
    return this.firestore
      .collection(collection)
      .where('userId', '==', userId)
      .limit(1)
      .get()
      .then((snap) => {
        return snap.docs.filter((doc) => doc.exists).length > 0;
      });
  }

  findUserParkMembership({
    parkId,
    userId,
  }: {
    parkId: string;
    userId: string;
  }): Promise<ParkMembership | undefined> {
    return this.firestore
      .collection(collection)
      .where('userId', '==', userId)
      .where('parkId', '==', parkId)
      .limit(1)
      .get()
      .then((snapshots) => {
        const { docs } = snapshots;
        if (docs.length === 0) return undefined;

        const doc = docs[0];
        return this.parseDoc(doc);
      });
  }

  getUserMemberships(userId: string): Promise<Membership[]> {
    return this.firestore
      .collection(collection)
      .where('userId', '==', userId)
      .get()
      .then((snapshots) => {
        const { docs } = snapshots;
        return docs.map(this.parseDoc);
      });
  }

  getParkMemberships(parkId: string): Promise<ParkMembership[]> {
    return this.firestore
      .collection(collection)
      .where('parkId', '==', parkId)

      .get()
      .then((snapshots) => {
        const { docs } = snapshots;
        return docs
          .map(this.parseMembership)
          .filter((d) => isParkMembership(d)) as ParkMembership[];
      });
  }

  private parseDoc(document: firebase.firestore.DocumentSnapshot): ParkMembership {
    return document.data() as ParkMembership;
  }

  private parseMembership(document: firebase.firestore.DocumentSnapshot): Membership {
    const data = document.data()!;

    return { ...data, id: document.id, createdAt: data.createdAt?.toDate() } as Membership;
  }
}
