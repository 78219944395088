import { Info, InfoAction } from 'data/entity/Info';
import { CreateInfoVariables, InfoRepository } from 'data/repositories/Info';
import { Operation, Resource } from 'data/Resource';
import firebase from 'firebase';
import { Container } from 'unstated-typescript';
import { EventBus } from 'services/event/bus';

import { InfoCreatedEvent, InfoUpdatedEvent } from 'services/event/events';
import { Languages } from 'data/entity/Park';
import logger from 'services/Logging';

export type InfoDetailsState = {
  info: Resource<Info | null>;
  saveOperation: Operation;
};

export type InfoActionInput = InfoAction & {
  files: Partial<Record<Languages, File>>;
};

export type CreateVariables = Omit<CreateInfoVariables, 'titleImageUrl' | 'userId' | 'actions'> & {
  titleImage?: File | null;
  actions: InfoActionInput[];
};

export type UpdateVariables = Omit<CreateInfoVariables, 'titleImageUrl' | 'userId' | 'actions'> & {
  titleImage?: File | null;
  id: string;
  actions: InfoActionInput[];
};

const previewImageName = 'title-image';

function getPreviewImageRef(id: string) {
  const storage = firebase.storage();
  const path = `${id}/${previewImageName}`;
  const ref = storage.ref(path);
  return ref;
}

function getActionImageRef(infoId: string, id: string, name: string) {
  const storage = firebase.storage();
  const path = `${infoId}/actions/${id}/${name}`;
  const ref = storage.ref(path);
  return ref;
}

export class InfoDetailsContainer extends Container<InfoDetailsState> {
  constructor(
    private repository: InfoRepository,
    private getUserId: () => Promise<string>,
    private eventBus: EventBus,
  ) {
    super();
  }

  state = {
    info: Resource.idle<Info>(),
    saveOperation: Operation.idle(),
  };

  async uploadActionFile(info: Info, actionId: string, file: File) {
    const ref = getActionImageRef(info.id, actionId, file.name);
    const task = ref.put(file);
    await task.then();
    return await (ref.getDownloadURL() as Promise<string>);
  }

  async create(vars: CreateVariables) {
    this.setState({
      ...this.state,
      saveOperation: Operation.loading(),
    });

    try {
      const userId = await this.getUserId();
      const id = this.repository.generateID();

      let titleImageUrl: string | null = null;
      if (vars.titleImage) {
        const ref = getPreviewImageRef(id);
        const task = ref.put(vars.titleImage);
        await task.then();
        titleImageUrl = await (ref.getDownloadURL() as Promise<string>);
      }

      try {
        const actions = await Promise.all(
          vars.actions.map<Promise<InfoAction>>(async (action) => {
            const urls = {};

            for (const key in action.files) {
              const file = action.files[key];
              const url = await this.uploadActionFile(info, action.id, file);
              urls[key] = url;
            }

            return {
              id: action.id,
              name: action.name,
              label: action.label,
              type: action.type,

              urls,
            };
          }),
        );

        const info = await this.repository.create({
          ...vars,
          actions,
          userId,
          id,
          titleImageUrl: titleImageUrl ?? undefined,
        });
        this.setState({
          ...this.state,
          info: Resource.success(info),
          saveOperation: Operation.success(),
        });

        this.eventBus.publish(new InfoCreatedEvent(info, vars.parkId));
      } catch (e) {
        logger.logError(e);
        if (titleImageUrl) {
          const path = `${id}/${previewImageName}`;
          await firebase.storage().ref(path).delete();
        }
        throw e;
      }
    } catch (e) {
      logger.logError(e);
      this.setState({
        ...this.state,
        saveOperation: Operation.error('Info konnte nicht erstellt werden.'),
      });
    }
  }

  async update(vars: UpdateVariables) {
    this.setState({
      ...this.state,
      saveOperation: Operation.loading(),
    });

    try {
      const userId = await this.getUserId();
      const info = await this.repository.get(vars.id);
      if (!info) {
        this.setState({
          ...this.state,
          saveOperation: Operation.error('Information mit ID existiert nicht.'),
        });
        return;
      }

      const actions = await Promise.all(
        (vars.actions ?? []).map<Promise<InfoAction>>(async (action) => {
          var urls = action.urls ?? {};
          for (const key in action.files) {
            const file = action.files[key];
            console.log(`Upload file for ${action.name} language ${key}`);
            const url = await this.uploadActionFile(info, action.id, file);
            urls[key] = url;
          }

          return {
            id: action.id,
            name: action.name,
            label: action.label,
            type: action.type,
            urls,
          };
        }),
      );

      const deletedImage = info.titleImageUrl !== null && vars.titleImage === null;
      const changedImage = vars.titleImage;
      let titleImageUrl: string | null = info.titleImageUrl;
      if (deletedImage) {
        titleImageUrl = null;
      } else if (vars.titleImage && changedImage) {
        const ref = getPreviewImageRef(info.id);
        const task = ref.put(vars.titleImage);
        await task.then();
        titleImageUrl = await (ref.getDownloadURL() as Promise<string>);
      }

      const updatedInfo = await this.repository.update({
        ...vars,
        actions,
        userId,
        titleImageUrl: titleImageUrl ?? undefined,
      });

      this.eventBus.publish(new InfoUpdatedEvent(updatedInfo));

      this.setState({
        ...this.state,
        info: Resource.success(updatedInfo),
        saveOperation: Operation.success(),
      });
    } catch (e) {
      logger.logError(e);
      this.setState({
        ...this.state,
        saveOperation: Operation.error('Info konnte nicht erstellt werden.'),
      });
    }
  }

  async load(infoId: string) {
    this.setState({
      ...this.state,
      info: Resource.loading(),
    });

    try {
      const info = await this.repository.get(infoId);

      this.setState({
        ...this.state,
        info: Resource.success(info),
      });
    } catch (e) {
      logger.logError(e);
      this.setState({
        ...this.state,
        info: Resource.error('Informationen konnten nicht geladen werden.'),
      });
    }
  }
}
