import { User } from 'data/user/User';
import { Info } from './Info';

export type ParkPermissions = {
  read: boolean;
  write: boolean;
  admin: boolean;
};

export type ParkMembership = {
  id: string;
  userId: string;
  parkId: string;
} & ParkPermissions;

export enum PermissionLevel {
  Read = 'read',
  Write = 'write',
  None = 'none',
}

export enum InfoProperty {
  Location = 'location',
  Tags = 'tags',
  Image = 'image',
  Content = 'content',
  Title = 'title',
  InfoType = 'infoType',
  Actions = 'actions',
}

export const InfoProperties = Object.values(InfoProperty);

export type InfoPermissions = {
  fallback: PermissionLevel;
  canDelete: boolean;
} & Partial<Record<InfoProperty, PermissionLevel>>;

export type InfoMembership = {
  id: string;
  userId: string;
  user?: User;
  createdAt: Date;
  createdBy: string;
  permissions: InfoPermissions;
  parkId: string;
  infoId: string;
  info?: Info;
};

export type Membership = InfoMembership | ParkMembership;

export function isParkMembership(membership: Membership): membership is ParkMembership {
  return (membership as { infoId?: string })?.infoId === undefined;
}

export function isInfoMembership(membership: Membership): membership is InfoMembership {
  return !isParkMembership(membership);
}

export function permissionsFromMembership(membership: Membership): InfoPermissions {
  if (isInfoMembership(membership)) return membership.permissions;

  if (isParkMembership(membership)) {
    if (membership.admin || membership.write)
      return { fallback: PermissionLevel.Write, canDelete: membership.admin };
    return { fallback: PermissionLevel.Read, canDelete: false };
  }

  return {
    fallback: PermissionLevel.None,
    canDelete: false,
  };
}

export type UserMembership = Omit<ParkMembership, 'userId'> & { user: User };
