import firebase from 'firebase';
import { IUserRepository } from '../../data/repositories/User';
import { User } from '../../data/user/User';
import { getUser, userFromDocument } from './shared';

export class FirebaseUserRepository implements IUserRepository {
  getEditors(): Promise<User[]> {
    return firebase
      .firestore()
      .collection('user')
      .where('email', '>=', '')
      .get()
      .then((snap) => {
        const { docs } = snap;

        return docs.map(userFromDocument);
      });
  }

  async getUser(id: string): Promise<User | null> {
    return getUser(id);
  }
}
