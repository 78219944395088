import { InfoRepository } from 'data/repositories/Info';
import { Operation } from 'data/Resource';
import { EventBus } from 'services/event/bus';
import { InfoDeletedEvent } from 'services/event/events';
import logger from 'services/Logging';
import { Container } from 'unstated-typescript';

export type DeleteInfoState = {
  operation: Operation;
};

export class DeleteInfoContainer extends Container<DeleteInfoState> {
  constructor(private repository: InfoRepository, private eventBus: EventBus) {
    super();
  }

  state = {
    operation: Operation.idle(),
  };

  public async delete(infoId: string) {
    this.setState({
      operation: Operation.idle(),
    });

    try {
      await this.repository.delete(infoId);
      this.setState({
        operation: Operation.success(),
      });
      this.eventBus.publish(new InfoDeletedEvent(infoId));
    } catch (e) {
      logger.logError(e);
      this.setState({
        operation: Operation.error('Info konnte nicht gelöscht werden.'),
      });
    }
  }
}
