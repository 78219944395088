import {
  InfoMembership,
  InfoPermissions,
  Membership,
  ParkMembership,
  UserMembership,
} from 'data/entity/Membership';

export enum MembershipRelations {
  User,
  Info,
  Park,
}

export type CreateParkMembershipVars = {
  userId: string;
  parkId: string;
  write: boolean;
  admin: boolean;
};

export type CreateInfoMembershipVars = {
  infoId: string;
  userId: string;
  permissions: InfoPermissions;
};

type BaseUpdateVars = {
  membershipId: string;
};

export type UpdateParkMembershipVars = BaseUpdateVars & {};

export type UpdateInfoMembershipVars = BaseUpdateVars & {
  permissions: InfoPermissions;
};

export type CreateMembershipVars = CreateInfoMembershipVars | CreateParkMembershipVars;

export type UpdateMembershipVars = UpdateInfoMembershipVars | UpdateParkMembershipVars;

export interface MembershipRepository {
  getUserMemberships(userId: string): Promise<Membership[]>;

  getParkMemberships(parkId: string): Promise<ParkMembership[]>;

  getParkInfoMemberships(
    parkId: string,
    relations?: MembershipRelations[],
  ): Promise<InfoMembership[]>;

  getParkMember(parkId: string): Promise<UserMembership[]>;

  findUserParkMembership(args: {
    parkId: string;
    userId: string;
  }): Promise<ParkMembership | undefined>;

  findById(membershipId: string): Promise<Membership | undefined>;

  hasMembership(userId: string): Promise<boolean>;

  create(vars: CreateMembershipVars): Promise<Membership>;

  update(vars: UpdateMembershipVars): Promise<void>;

  delete(membershipId: string): Promise<void>;
}
