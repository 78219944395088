import { IUserRepository } from './data/repositories/User';
import IAuthenticationService from './data/services/Authentication';
import { FirebaseUserRepository } from './remote/repository/FirebaseUser';
import { FirebaseAuthService } from './remote/services/FirebaseAuth';
import { ParkRepository } from 'data/repositories/Park';
import { FirestoreParkRepository } from 'remote/repository/Park';
import { AllParksContainer } from 'model/parks/All';
import firebase from 'firebase';
import { MembershipRepository } from 'data/repositories/Membership';
import { FirestoreMembershipRepository } from 'remote/repository/membership';
import { ActiveParkContainer } from 'model/parks/Active';
import { ParkInfosContainer } from 'model/info/All';
import { InfoRepository } from 'data/repositories/Info';
import { FirestoreInfoRepository } from 'remote/repository/Info';
import { ParkDetailsContainer } from 'model/parks/Detail';
import { InfoDetailsContainer } from 'model/info/Detail';
import { DeleteInfoContainer } from 'model/info/Delete';
import { EventBus } from 'services/event/bus';
import { InfoStore } from 'remote/store/info';
import { ParkMemberContainer } from 'model/parks/Member';
import { UserInfoContainer } from 'model/info/User';
import { MembershipDetailContainer } from 'model/membership/Detail';
import { AllEditorsContainer } from 'model/user/AllEditor';

const eventBus = new EventBus();

export const UserRepository = (): IUserRepository => {
  return new FirebaseUserRepository();
};

export const getMembershipRepository = (): MembershipRepository => {
  return new FirestoreMembershipRepository(firebase.firestore());
};

const infoRepository = new InfoStore(new FirestoreInfoRepository());

export const getInfoRepository = (): InfoRepository => {
  return infoRepository;
};

export const AuthenticationService = (): IAuthenticationService => {
  return new FirebaseAuthService(UserRepository(), eventBus);
};

export const getParkDetailsContainer = (): ParkDetailsContainer => {
  return new ParkDetailsContainer(getParkRepository(), getUserId);
};

const getParkRepository = (): ParkRepository => new FirestoreParkRepository(firebase.firestore());

export const getAllParksContainer = (): AllParksContainer => {
  return new AllParksContainer(
    getParkRepository(),
    getMembershipRepository(),
    () =>
      AuthenticationService()
        .getAuthToken()
        .then((token) => token.userId),
    eventBus,
  );
};

function getUserId(): Promise<string> {
  return AuthenticationService()
    .getAuthToken()
    .then((token) => token.userId);
}

export const getActiveParkContainer = (): ActiveParkContainer => {
  return new ActiveParkContainer(
    getParkRepository(),
    getMembershipRepository(),
    getUserId,
    eventBus,
  );
};

const parkInfoContainer: Record<string, ParkInfosContainer> = {};

export const getParkInfoContainer = (parkId: string): ParkInfosContainer => {
  if (parkInfoContainer[parkId]) return parkInfoContainer[parkId];
  const container = new ParkInfosContainer(getInfoRepository(), parkId, eventBus);
  parkInfoContainer[parkId] = container;
  return container;
};

export const getUserInfoContainer = (infoIds: string[]): UserInfoContainer => {
  const container = new UserInfoContainer(getInfoRepository(), eventBus, infoIds);
  return container;
};

export function getInfoDetailsContainer(): InfoDetailsContainer {
  return new InfoDetailsContainer(getInfoRepository(), getUserId, eventBus);
}

export function getDeleteInfoContainer(): DeleteInfoContainer {
  return new DeleteInfoContainer(getInfoRepository(), eventBus);
}

export const getParkMemberContainer = (parkId: string): ParkMemberContainer => {
  const container = new ParkMemberContainer(getMembershipRepository(), eventBus, parkId);

  return container;
};

export const getMembershipDetailContainer = (): MembershipDetailContainer => {
  const container = new MembershipDetailContainer(getMembershipRepository(), eventBus);
  return container;
};

export const getAllEditorsContainer = (): AllEditorsContainer => {
  return new AllEditorsContainer(UserRepository(), eventBus);
};
