import {
  Box,
  CircularProgress,
  FormControlLabel,
  Grid,
  Paper,
  Switch,
  Toolbar,
} from '@material-ui/core';
import { InfoType, InfoTypes, mapToString } from 'data/entity/Info';
import { ActiveParkContainer } from 'model/parks/Active';
import { getParkInfoContainer } from 'Provider';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { InfoMap } from 'ui/components/forms/Location';
import { Subscribe } from 'unstated-typescript';

type InfoTypeVisibilityMap = Record<InfoType, boolean>;
const defaultVisibilityMap = InfoTypes.reduce((acc, val) => {
  return { ...acc, [val]: true };
}, {} as InfoTypeVisibilityMap);

export function Dashboard() {
  const params = useParams<{ parkId: string }>();
  const [visibilityMap, setVisibilityMap] = React.useState(defaultVisibilityMap);

  return (
    <Grid container spacing={2} xs={12}>
      <Grid item xs={12} md={8}>
        <Paper>
          <Toolbar>
            <h2>Karte</h2>
          </Toolbar>
          <Subscribe to={[getParkInfoContainer(params.parkId), ActiveParkContainer]}>
            {(container, activeParkContainer) => {
              const { state } = container;

              const {
                infos: { isIdle, isLoading, hasError, data: infos, message: error },
              } = state;

              if (isIdle) container.load();

              if (isIdle || isLoading) return <CircularProgress />;

              if (hasError) return <p>{error}</p>;

              if (infos?.length === 0) return <p>Noch keine Informationen erstellt.</p>;

              const park = activeParkContainer.state.park.data;
              const {
                location: { lat, lng },
              } = park!;

              const visibleInfos = infos!.filter((info) => visibilityMap[info.infoType]);

              return <InfoMap infos={visibleInfos} initial={{ lat, lng }} />;
            }}
          </Subscribe>
        </Paper>
      </Grid>
      <Grid item container spacing={2} xs={12} md={4}>
        <Grid item xs={12}>
          <Paper>
            <Toolbar>
              <h2>Ansicht</h2>
            </Toolbar>
            <InfoTypeSelection
              onChange={(v) => {
                setVisibilityMap(v);
              }}
            />
          </Paper>
        </Grid>
        {/* <Grid item xs={12}>
          <Paper>
            <Toolbar>
              <h2>Suche</h2>
              <InfoSearch
                onSelected={(info) => {
                  return false;
                }}
              />
            </Toolbar>
          </Paper>
        </Grid> */}
      </Grid>
    </Grid>
  );
}

// type InfoSearchProps = {
//   onSelected: (info: Info) => boolean;
// };
// function InfoSearch(props: InfoSearchProps) {
//   const { onSelected } = props;

//   return (
//     <Box>
//       <TextField variant="outlined" placeholder="Name..." />
//     </Box>
//   );
// }

type InfoTypeProps = {
  onChange: (values: InfoTypeVisibilityMap) => void;
};

function InfoTypeSelection(props: InfoTypeProps) {
  const { onChange } = props;

  const [visibilityMap, setVisibilityMap] = React.useState<InfoTypeVisibilityMap>(
    defaultVisibilityMap,
  );

  useEffect(() => {
    onChange(visibilityMap);
  }, [visibilityMap, onChange]);

  return (
    <Box p={2} pt={0}>
      <Grid container spacing={1}>
        {InfoTypes.map((infoType) => {
          const name = mapToString(infoType);
          const selected = visibilityMap[infoType];

          return (
            <Grid item xs={12} sm={6} md={6} justify="flex-start">
              <FormControlLabel
                style={{ width: '100%' }}
                control={
                  <Switch
                    checked={selected}
                    onChange={(v) => {
                      const newMap = { ...visibilityMap, [infoType]: !selected };
                      setVisibilityMap(newMap);
                    }}
                    name="checkedB"
                    color="primary"
                  />
                }
                label={name}
              />
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
}
