import {
  CollectionReference,
  DocumentData,
  DocumentSnapshot,
  QueryDocumentSnapshot,
} from '@firebase/firestore-types';
import { GpsPosition, Info, InfoAction, InfoType, LanguageSpecificInfos } from 'data/entity/Info';
import { CreateInfoVariables, InfoRepository, UpdateInfoVariables } from 'data/repositories/Info';
import firebase from 'firebase';
import { removeNotExisting } from './shared';

type InfoActionDto = Omit<InfoAction, 'urls'> & { url: InfoAction['urls'] };
type InfoDto = {
  completed?: boolean;
  content: Record<string, LanguageSpecificInfos>;
  created: Date;
  createdBy: string;
  deleted: boolean;
  deletedAt?: Date | null;
  deletedBy?: string | null;
  infoType: InfoType;
  lastModified: Date;
  lastModifiedBy: string;
  position: GpsPosition;
  tags: string[];
  title: string;
  titleImageUrl: string | null;
  autoTranslate: boolean;
  parkId?: string;
  hideImageAuthor?: boolean | null;
  imageAuthor: string | undefined | null;
  actions?: InfoActionDto[] | null;
};
export class FirestoreInfoRepository implements InfoRepository {
  getInfoByIds(infoIds: string[]): Promise<Info[]> {
    if (infoIds.length === 0) return Promise.resolve([]);
    return firebase
      .firestore()
      .collection('infos')
      .where(firebase.firestore.FieldPath.documentId(), 'in', infoIds)
      .get()
      .then((snap) => {
        const docs = removeNotExisting(snap.docs);
        return docs.map(this.mapDataFromDoc);
      });
  }

  getParkInfos(parkId: string): Promise<Info[]> {
    return this.parkInfoCollection(parkId)
      .where('deleted', '==', false)
      .get()
      .then((snap) => {
        const docs = removeNotExisting(snap.docs);
        return docs.map(this.mapDataFromDoc);
      });
  }

  generateID(): string {
    return firebase.firestore().collection('infos').doc().id;
  }

  private mapDataToInfo = (doc: QueryDocumentSnapshot): Info => {
    const data = doc.data();
    return this.mapToInfo(data, doc.id);
  };

  private mapToInfo = (data: DocumentData, id: string): Info => {
    const hasNewDataFormat = data.content !== undefined;

    const actions: InfoAction[] =
      data.actions?.map((action) => {
        const a: InfoAction = {
          id: action.id,
          name: action.name,
          urls: action.url,
          label: action.label,
          type: action.type,
        };
        return a;
      }) ?? [];

    return {
      id: id,
      tags: data.tags === undefined ? [] : data.tags,
      completed: data.completed === undefined || data.completed === null ? false : data.completed,
      title: data.title,
      position: data.position,
      deleted: data.deleted,
      titleImageUrl: data.titleImageUrl,

      content: hasNewDataFormat
        ? data.content
        : {
            de: {
              shortDescription: data.shortDescription,
              fullDescription: data.fullDescription,
            },
          },
      infoType: data.infoType,
      created: data.created.toDate(),
      createdBy: data.createdBy,
      imageAuthor: data.imageAuthor ?? undefined,
      deletedBy: data.deletedBy,
      lastModified: data.lastModified ? data.lastModified.toDate() : null,
      deletedAt: null,
      parkId: data.parkId,
      hideImageAuthor: data.hideImageAuthor ?? false,
      actions,
    };
  };

  private mapDataFromDoc = (doc: DocumentSnapshot): Info => {
    const data = doc.data()!;
    return this.mapToInfo(data, doc.id);
  };

  private parkInfoCollection(parkId: string): CollectionReference {
    return firebase.firestore().collection('locations').doc(parkId).collection('infos');
  }

  async create(vars: CreateInfoVariables): Promise<Info> {
    const collection = this.parkInfoCollection(vars.parkId);
    const doc = vars.id ? collection.doc(vars.id) : collection.doc();
    const data: InfoDto = {
      completed: vars.completed,
      content: vars.content,
      created: new Date(),
      createdBy: vars.userId,
      titleImageUrl: vars.titleImageUrl ?? null,
      title: vars.title,
      deleted: false,
      lastModified: new Date(),
      lastModifiedBy: vars.userId,
      infoType: vars.type,
      position: vars.position,
      tags: vars.tags,
      autoTranslate: true,
      imageAuthor: !vars.titleImageUrl ? null : vars.imageAuthor ?? null,
      actions: vars.actions?.map((a) => {
        const action = { ...a, url: a.urls };
        delete action.urls;
        return action;
      }),
      hideImageAuthor: vars.hideImageAuthor ?? false,
    };

    await doc.set(data);

    return {
      ...data,
      id: doc.id,
      completed: data.completed ?? true,
      deletedBy: null,
      deletedAt: null,
      parkId: vars.parkId,
      hideImageAuthor: false,
      actions: [],
    };
  }

  async update(vars: UpdateInfoVariables): Promise<Info> {
    const update: Partial<InfoDto> = {
      completed: vars.completed,
      content: vars.content,
      titleImageUrl: vars.titleImageUrl ?? null,
      title: vars.title,
      infoType: vars.type,
      position: vars.position,
      tags: vars.tags,
      lastModified: new Date(),
      lastModifiedBy: vars.userId,
      autoTranslate: true,
      actions: vars.actions?.map((a) => {
        const action = { ...a, url: a.urls };
        delete action.urls;
        return action;
      }),
      hideImageAuthor: vars.hideImageAuthor ?? false,
      imageAuthor: !vars.titleImageUrl ? null : vars.imageAuthor ?? null,
    };
    const doc = this.parkInfoCollection(vars.parkId).doc(vars.id);
    await doc.update(update);

    const docData = await doc.get();

    return this.mapDataFromDoc(docData);
  }
  async delete(id: string): Promise<void> {
    const info = await this.get(id);
    if (!info) {
      return;
    }

    const parkId = ((info as unknown) as { parkId: string }).parkId ?? 'sanssouci';

    const sourceDoc = this.parkInfoCollection(parkId).doc(id);
    const userId = firebase.auth().currentUser!.uid;
    await sourceDoc.update({
      deleted: true,
      deletedAt: new Date(),
      deletedBy: userId,
    });
    return;
  }
  async get(id: string): Promise<Info | null> {
    const docData = await firebase.firestore().collection('infos').doc(id).get();

    if (!docData.exists) {
      const newDoc = await this.parkInfoCollection('sanssouci').doc(id).get();
      if (!newDoc.exists) return null;
      if ((newDoc.data() as InfoDto).deleted) return null;

      return this.mapDataFromDoc(newDoc);
    }
    if ((docData.data() as InfoDto).deleted) return null;
    return this.mapDataFromDoc(docData);
  }
}
