import { CircularProgress, Grid } from '@material-ui/core';
import { Info, InfoType, mapToString } from 'data/entity/Info';
import { InfoMembership, isInfoMembership, isParkMembership } from 'data/entity/Membership';
import { Resource } from 'data/Resource';
import { MaterialTableProps } from 'material-table';
import { ParkInfosContainer } from 'model/info/All';
import { UserContainer } from 'model/LoginState';
import { getUserInfoContainer } from 'Provider';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UnauthorizedScreen } from 'ui/components/auth/Unauthorized';
import { LocalizedTable } from 'ui/components/Table';
import { Subscribe } from 'unstated-typescript';

function stripHtml(content: string): string {
  if (content === null || content === undefined) return '';

  return content.replace(/<[^>]*>?/gm, '');
}

function calculateInfoProgress(item: Info): number {
  const max = 100;
  if (item.completed) return max;
  const germanContent = item.content['de'];
  const requiredProperties = [
    item.title,
    item.titleImageUrl,
    item.infoType,
    item.position,
    germanContent === undefined ? undefined : germanContent.shortDescription,
    germanContent?.fullDescription !== undefined
      ? stripHtml(germanContent.fullDescription)
      : undefined,
    item.content['en'] !== undefined ? item.content['en'].shortDescription : undefined,
    item.content['en'] !== undefined && item.content['en'].fullDescription
      ? stripHtml(item.content['en'].fullDescription)
      : undefined,
  ];
  const progressPerProperty = max / requiredProperties.length;
  var progress = 0;
  requiredProperties.forEach((p) => {
    if (p === undefined || p === null) return;

    if (typeof p === typeof '') {
      if ((p as string).trim() !== '') {
        progress += progressPerProperty;
      }
    } else {
      progress += progressPerProperty;
    }
  });

  return progress;
}

enum MissingContent {
  ENPreview,
  ENDescription,
}

function mapMissingContent(value: MissingContent): string {
  switch (value) {
    case MissingContent.ENDescription:
      return 'Inhalt (en)';
    case MissingContent.ENPreview:
      return 'Kurzbeschr. (en)';
    default:
      return '?';
  }
}

export function InfoOverviewPage() {
  const history = useHistory();
  const params = useParams<{ parkId?: string }>();
  const parkId = params.parkId;

  return (
    <Subscribe to={[UserContainer]}>
      {(userContainer) => {
        const memberships = userContainer.state.memberships.data ?? [];

        if (!parkId) {
          const infoIds = memberships
            .filter(isInfoMembership)
            .map((ms) => (ms as InfoMembership).infoId);

          const container = getUserInfoContainer(infoIds);
          container.load();

          return (
            <Subscribe to={[container]}>
              {(userInfoContainer) => {
                const { state } = userInfoContainer;
                return (
                  <InfoTable
                    infosRes={state.infos}
                    canCreate={false}
                    canDelete={false}
                    displayProgress={false}
                    parkId={parkId}
                  />
                );
              }}
            </Subscribe>
          );
        }
        const hasParkMembership = memberships.find(
          (ms) => isParkMembership(ms) && ms.parkId === parkId,
        );

        if (!hasParkMembership) return <UnauthorizedScreen />;

        const showDetails = (info: Info) => {
          const url = '/park/' + (info.parkId ?? parkId) + '/infos/' + info.id;
          history.push(url);
        };

        const buildLink = (info: Info) => {
          const url = '/park/' + (info.parkId ?? parkId) + '/infos/' + info.id;
          return url;
        };

        const createNew = () => {
          const url = '/park/' + parkId + '/info';
          history.push(url);
        };

        return (
          <Subscribe to={[ParkInfosContainer]}>
            {(container: ParkInfosContainer) => {
              const { infos: infosRes } = container.state;
              if (infosRes.isIdle) {
                container.load();
              }

              if (infosRes.isIdle || infosRes.isLoading) return <CircularProgress />;

              if (infosRes.hasError) return <p>{infosRes.message}</p>;

              const infos = infosRes.data ?? [];
              const infosWithProgress = infos.map((info) => {
                const progress = calculateInfoProgress(info);
                return { ...info, progress };
              });

              const incomplete: (Info & { missing: MissingContent[] })[] = [];

              infos.forEach((i) => {
                const germanContent = i.content['de'];
                const englishContent = i.content['en'];
                if (i.completed || i.infoType === InfoType.Bin) {
                  return;
                }

                if (!englishContent) {
                  incomplete.push({
                    ...i,
                    missing: [MissingContent.ENDescription, MissingContent.ENPreview],
                  });
                  return;
                }

                const previewTranslationMissing =
                  germanContent.shortDescription.trim() !== '' &&
                  (englishContent?.shortDescription?.trim() ?? '') === '';
                const contentTranslationMissing =
                  stripHtml(germanContent.fullDescription.trim()) !== '' &&
                  stripHtml(englishContent?.fullDescription?.trim() ?? '') === '';

                const missing: MissingContent[] = [];

                if (contentTranslationMissing) {
                  missing.push(MissingContent.ENDescription);
                }

                if (previewTranslationMissing) {
                  missing.push(MissingContent.ENPreview);
                }

                if (missing.length > 0) {
                  incomplete.push({ ...i, missing });
                }
              });

              return (
                <Grid item container spacing={2}>
                  <Grid item xs={12}>
                    <LocalizedTable
                      tableId="infos"
                      entityname="Info"
                      editRow={(park) => {
                        showDetails(park);
                      }}
                      buildLink={buildLink}
                      create={{
                        createNew,
                      }}
                      title="Infos"
                      columns={[
                        {
                          title: 'Name',
                          field: 'title',
                          render: (park: Info) => {
                            return `${park.title}`;
                          },
                        },
                        {
                          title: 'Fortschritt',
                          type: 'numeric',
                          field: 'progress',
                          render: (info: Info & { progress: number }) => {
                            return `${info.progress}%`;
                          },
                        },
                        {
                          title: 'Typ',
                          type: 'numeric',
                          field: 'infoType',
                          render: (park: Info) => {
                            return mapToString(park.infoType);
                          },
                        },
                        {
                          title: 'Tags',
                          type: 'string',
                          field: 'tags',
                          render: (park: Info) => {
                            return park.tags.join(', ');
                          },
                        },
                        {
                          title: 'erstellt',
                          type: 'time',
                          field: 'created',
                          render: (park: Info) => {
                            return park.created.toLocaleDateString();
                          },
                        },
                        {
                          title: 'letzte Änderung',
                          type: 'time',
                          field: 'lastModified',
                          defaultSort: 'desc',
                          render: (park: Info) => {
                            return park.lastModified?.toLocaleDateString() ?? '-';
                          },
                        },
                      ]}
                      data={infosWithProgress}
                    />
                  </Grid>

                  <Grid item xs={12}>
                    <LocalizedTable
                      tableId="incomplete"
                      entityname="Info"
                      editRow={(park) => {
                        showDetails(park);
                      }}
                      buildLink={buildLink}
                      create={{
                        createNew,
                      }}
                      title="Unvollständig"
                      columns={[
                        {
                          title: 'Name',
                          field: 'title',
                          render: (park: Info) => {
                            return `${park.title}`;
                          },
                        },
                        {
                          title: 'Fehlend',
                          field: 'missing',
                          render: (info: Info & { missing: MissingContent[] }) => {
                            const str = info.missing.map(mapMissingContent).join(', ');
                            return str;
                          },
                        },

                        {
                          title: 'Typ',
                          type: 'numeric',
                          field: 'infoType',
                          render: (park: Info) => {
                            return mapToString(park.infoType);
                          },
                        },
                        {
                          title: 'Tags',
                          type: 'string',
                          field: 'tags',
                          render: (park: Info) => {
                            return park.tags.join(', ');
                          },
                        },
                        {
                          title: 'erstellt',
                          type: 'time',
                          field: 'created',
                          render: (park: Info) => {
                            return park.created.toLocaleDateString();
                          },
                        },
                        {
                          title: 'letzte Änderung',
                          type: 'time',
                          field: 'lastModified',
                          defaultSort: 'desc',
                          render: (park: Info) => {
                            return park.lastModified?.toLocaleDateString() ?? '-';
                          },
                        },
                      ]}
                      data={incomplete}
                    />
                  </Grid>
                </Grid>
              );
            }}
          </Subscribe>
        );
      }}
    </Subscribe>
  );
}

type InfoTableProps = {
  canDelete: boolean;
  infosRes: Resource<Info[]>;
  parkId?: string;
  canCreate: boolean;
  displayProgress: boolean;
};
function InfoTable(props: InfoTableProps) {
  const { infosRes, parkId, canCreate, displayProgress } = props;

  const history = useHistory();

  if (infosRes.isIdle || infosRes.isLoading) return <CircularProgress />;

  if (infosRes.hasError) return <p>{infosRes.message}</p>;

  const showDetails = (info: Info) => {
    const url = '/park/' + (info.parkId ?? parkId) + '/infos/' + info.id;
    history.push(url);
  };

  const buildLink = (info: Info) => {
    const url = '/park/' + (info.parkId ?? parkId) + '/infos/' + info.id;
    return url;
  };

  const createNew = () => {
    const url = '/park/' + parkId + '/info';
    history.push(url);
  };

  const infosWithProgress = (infosRes.data ?? []).map((info) => {
    const progress = calculateInfoProgress(info);
    return { ...info, progress };
  });

  const columns = [
    {
      title: 'Name',
      field: 'title',
      render: (park: Info) => {
        return `${park.title}`;
      },
    },
    displayProgress && {
      title: 'Fortschritt',
      type: 'numeric',
      field: 'progress',
      render: (info: Info & { progress: number }) => {
        return `${info.progress}%`;
      },
    },

    {
      title: 'Typ',
      type: 'numeric',
      field: 'infoType',
      render: (park: Info) => {
        return mapToString(park.infoType);
      },
    },
    {
      title: 'Tags',
      type: 'string',
      field: 'tags',
      render: (park: Info) => {
        return park.tags.join(', ');
      },
    },
    {
      title: 'erstellt',
      type: 'time',
      field: 'created',
      render: (park: Info) => {
        return park.created.toLocaleDateString();
      },
    },
    {
      title: 'letzte Änderung',
      type: 'time',
      field: 'lastModified',
      defaultSort: 'desc',
      render: (park: Info) => {
        return park.lastModified?.toLocaleDateString() ?? '-';
      },
    },
  ];

  return (
    <Grid item xs={12}>
      <LocalizedTable
        tableId="infos"
        entityname="Info"
        editRow={(park) => {
          showDetails(park);
        }}
        buildLink={buildLink}
        create={
          canCreate
            ? {
                createNew,
              }
            : undefined
        }
        title="Infos"
        columns={columns.filter(Boolean) as MaterialTableProps<Info>['columns']}
        data={infosWithProgress}
      />
    </Grid>
  );
}
