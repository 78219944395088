import { Park } from 'data/entity/Park';
import { CreateParkVariables, ParkRepository, UpdateParkVariables } from 'data/repositories/Park';
import { Operation, Resource } from 'data/Resource';
import firebase from 'firebase';
import logger from 'services/Logging';
import { Container } from 'unstated-typescript';

export type ParkDetailsState = {
  park: Resource<Park | null>;
  saveOperation: Operation;
};

export type CreateVariables = Omit<CreateParkVariables, 'titleImageUrl' | 'id' | 'userId'> & {
  titleImage?: File | null;
};

export type UpdateVariables = Omit<CreateParkVariables, 'titleImageUrl' | 'userId'> & {
  titleImage?: File | null;
};

function getPreviewImageRef(id: string) {
  const storage = firebase.storage();
  const path = `${id}/preview-image`;
  const ref = storage.ref(path);
  return ref;
}

export class ParkDetailsContainer extends Container<ParkDetailsState> {
  constructor(private repository: ParkRepository, private getUserId: () => Promise<string>) {
    super();
  }

  state = {
    park: Resource.idle<Park>(),
    saveOperation: Operation.idle(),
  };

  async create(vars: CreateVariables) {
    this.setState({
      ...this.state,
      saveOperation: Operation.loading(),
    });

    try {
      const id = this.repository.generateId();
      let imageUrl: string | null = null;
      if (vars.titleImage) {
        const ref = getPreviewImageRef(id);
        const task = ref.put(vars.titleImage);
        await task.then();
        imageUrl = await ((await task).ref.getDownloadURL() as Promise<string>);
      }

      const userId = await this.getUserId();
      const createVariables: CreateParkVariables = {
        ...vars,
        userId,
        id,
        titleImageUrl: imageUrl,
      };

      const park = await this.repository.create(createVariables);
      this.setState({
        ...this.state,
        saveOperation: Operation.success(),
        park: Resource.success(park),
      });
    } catch (e) {
      logger.logError(e);
      this.setState({
        ...this.state,
        saveOperation: Operation.error('Park konnte nicht angelegt werden.'),
      });
    }
  }

  async update(vars: UpdateVariables) {
    this.setState({
      ...this.state,
      saveOperation: Operation.loading(),
    });

    try {
      const { id } = vars;
      const currentPark = await this.repository.findById(id);
      if (!currentPark) {
        this.setState({
          ...this.state,
          saveOperation: Operation.error('Park konnte nicht gefunden werden.'),
        });
        return;
      }

      const deletedImage = currentPark.titleImageUrl !== null && vars.titleImage === null;
      const changedImage = currentPark.titleImageUrl !== null && vars.titleImage;

      let imageUrl: string | null = currentPark?.titleImageUrl;
      if (deletedImage) {
        imageUrl = null;
      }
      if (changedImage && vars.titleImage) {
        const ref = getPreviewImageRef(id);
        const task = ref.put(vars.titleImage);
        await task.then();
        imageUrl = await ((await task).ref.getDownloadURL() as Promise<string>);
      }

      const userId = await this.getUserId();
      const createVariables: UpdateParkVariables = {
        ...vars,
        userId,
        id,
        titleImageUrl: imageUrl,
      };

      const park = await this.repository.update(createVariables);
      this.setState({
        ...this.state,
        saveOperation: Operation.success(),
        park: Resource.success(park),
      });
    } catch (e) {
      logger.logError(e);
      this.setState({
        ...this.state,
        saveOperation: Operation.error('Park konnte nicht angelegt werden.'),
      });
    }
  }

  public setPark(park: Park) {
    this.setState({
      ...this.setPark,
      park: Resource.success(park),
    });
  }

  async load(parkId: string) {
    this.setState({
      ...this.state,
      park: Resource.loading(),
    });

    try {
      const park = await this.repository.findById(parkId);

      this.setState({
        ...this.state,
        park: Resource.success(park ?? null),
      });
    } catch (e) {
      logger.logError(e);

      this.setState({
        ...this.state,
        park: Resource.error('Parkanlagen konnten nicht geladen werden.'),
      });
    }
  }
}
