import { ActiveParkContainer } from 'model/parks/Active';
import { AllParksContainer } from 'model/parks/All';
import React from 'react';
import { Subscribe } from 'unstated-typescript';

export type ActiveParkSelectionBuildArgs = {
    activeParkContainer : ActiveParkContainer,
    parksContainer : AllParksContainer,
}

export type ActiveParkSelectionProps = {

    builder: (args : ActiveParkSelectionBuildArgs) => JSX.Element;
}
export function ActiveParkSelectionBuilder(props: ActiveParkSelectionProps){
    const {builder} = props;
    return <Subscribe to={[ActiveParkContainer]}>
    {
      (activeParkContainer :ActiveParkContainer) => {
        const activeParkResource = activeParkContainer.state.activeParkId;
        const {isIdle} = activeParkResource;
        if(isIdle){
          activeParkContainer.load();
        }

        return <Subscribe to={[AllParksContainer]}>
          {
            (parksContainer) => {

              const parksResource = parksContainer.state.parks;
              const {isIdle} = parksResource;
              if(isIdle){
                parksContainer.load();
              }
              const args : ActiveParkSelectionBuildArgs = {
                  activeParkContainer,
                parksContainer,
              };

              return builder(args);
            }
          }
        </Subscribe>

      }
    }

  </Subscribe>
}