import { Park } from 'data/entity/Park';
import { ParkRepository } from 'data/repositories/Park';
import { Container } from 'unstated-typescript';
import { Resource } from 'data/Resource';
import { MembershipRepository } from 'data/repositories/Membership';
import { EventBus } from 'services/event/bus';
import { LogoutEvent } from 'services/event/events';
import logger from 'services/Logging';

export type AllParksState = {
  parks: Resource<Park[]>;
};

export class AllParksContainer extends Container<AllParksState> {
  constructor(
    private repository: ParkRepository,
    private membershipRepository: MembershipRepository,
    private getUserId: () => Promise<string>,
    private eventBus: EventBus,
  ) {
    super();

    eventBus.subscribe(LogoutEvent.Name, () => {
      this.setState({
        parks: Resource.idle<Park[]>(),
      });
    });
  }

  state = {
    parks: Resource.idle<Park[]>(),
  };

  async load() {
    this.setState({
      ...this.state,
      parks: Resource.loading(),
    });

    try {
      const userId = await this.getUserId();
      const memberships = await this.membershipRepository.getUserMemberships(userId);

      const parks = await Promise.all(
        memberships.map((membership) => this.repository.findById(membership.parkId)),
      );
      const existingParks = parks.filter((park) => park !== undefined) as Park[];

      this.setState({
        ...this.state,
        parks: Resource.success(existingParks),
      });
    } catch (e) {
      logger.logError(e);

      this.setState({
        ...this.state,
        parks: Resource.error('Parkanlagen konnten nicht geladen werden.'),
      });
    }
  }
}
