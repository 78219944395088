import { Membership } from 'data/entity/Membership';
import {
  CreateMembershipVars,
  MembershipRepository,
  UpdateMembershipVars,
} from 'data/repositories/Membership';
import { Operation, Resource } from 'data/Resource';
import { EntityDetailState, EntityDetailStateContainer } from 'model/base';
import { EventBus } from 'services/event/bus';

export type MembershipDetailState = EntityDetailState<Membership>;

export class MembershipDetailContainer extends EntityDetailStateContainer<
  Membership,
  CreateMembershipVars,
  UpdateMembershipVars,
  MembershipDetailState
> {
  constructor(private repository: MembershipRepository, private eventBus: EventBus) {
    super();
  }

  protected executeCreate(vars: CreateMembershipVars): Promise<Membership> {
    return this.repository.create(vars);
  }
  protected async executeUpdate(vars: UpdateMembershipVars): Promise<Membership> {
    await this.repository.update(vars);
    return { ...this.state.entity.data!, ...vars };
  }
  protected executeDelete(id: string): Promise<void> {
    return this.repository.delete(id);
  }
  protected getEntityId(): string {
    return this.state.entity.data!.id;
  }
  protected find(id: string): Promise<Membership | null> {
    return this.repository.findById(id).then((v) => v ?? null);
  }
  protected initialState(): MembershipDetailState {
    return {
      entity: Resource.idle(),
      save: Operation.idle(),
      delete: Operation.idle(),
    };
  }
}
