import { Info } from 'data/entity/Info';
import { Event } from './bus';

export class InfoDeletedEvent extends Event {
  static Name = 'InfoDeleted';

  public get eventName(): string {
    return InfoDeletedEvent.Name;
  }

  public constructor(public infoId: string) {
    super();
  }
}

export class InfoCreatedEvent extends Event {
  static Name = 'InfoCreated';
  public get eventName(): string {
    return InfoCreatedEvent.Name;
  }

  public constructor(public info: Info, public parkId: string) {
    super();
  }
}

export class InfoUpdatedEvent extends Event {
  static Name = 'InfoUpdated';
  public get eventName(): string {
    return InfoUpdatedEvent.Name;
  }
  public constructor(public info: Info) {
    super();
  }
}

export class LogoutEvent extends Event {
  static Name = 'LogoutEvent';

  public get eventName(): string {
    return LogoutEvent.Name;
  }
}
