import { red } from '@material-ui/core/colors';
import { deDE } from '@material-ui/core/locale';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { APP_VERSION, IS_PRODUCTION } from 'config';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

const theme = createMuiTheme(
  {
    palette: {
      primary: { main: '#003022' },
      error: red,
    },
    typography: {
      button: {
        textTransform: 'none',
      },
    },
  },
  deDE,
);

if (IS_PRODUCTION) {
  Sentry.init({
    dsn: 'https://b29debd8b9e947cdb52d0772a501913e@o947359.ingest.sentry.io/5896731',
    integrations: [],
    release: APP_VERSION,
    attachStacktrace: true,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.0,
  });
}
ReactDOM.render(
  <React.StrictMode>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
