import {
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import {
  InfoMembership,
  InfoPermissions,
  InfoProperties,
  isInfoMembership,
  isParkMembership,
  Membership,
  ParkMembership,
  ParkPermissions,
  PermissionLevel,
} from 'data/entity/Membership';
import { CreateInfoMembershipVars, UpdateInfoMembershipVars } from 'data/repositories/Membership';
import { ParkInfosContainer } from 'model/info/All';
import { UserContainer } from 'model/LoginState';
import { MembershipDetailContainer } from 'model/membership/Detail';
import { AllEditorsContainer } from 'model/user/AllEditor';
import { getMembershipDetailContainer, getParkInfoContainer } from 'Provider';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSuccessAlert } from 'ui/components/form/feedback';
import { FormWrapper } from 'ui/components/form/FormWrapper';
import { Subscribe } from 'unstated-typescript';
import { useQuery } from 'util/context/query';
import { getFriendlyInfoProperty, getFriendlyPermissionLevel } from 'util/i18n';

type QueryMembershipType = 'park' | 'info';

export function MembershipDetailsPage() {
  const { parkId, membershipId } = useParams<{ parkId: string; membershipId?: string }>();
  const query = useQuery();

  const container = getMembershipDetailContainer();
  if (membershipId) {
    container.load(membershipId);
  }

  return (
    <Subscribe to={[UserContainer, container, getParkInfoContainer(parkId)]}>
      {(userContainer, membershipContainer) => {
        const { state: membershipState } = membershipContainer;

        const { entity: membershipRes } = membershipState;

        if (membershipRes.isLoading) return <CircularProgress />;

        if (membershipRes.hasError) return <p>Mitgliedschaft konnte nicht geladen werden.</p>;

        if (membershipRes.hasData && !membershipRes.data) {
          return <p>Mitgliedschaft existiert nicht.</p>;
        }

        const type = query.get('type') as QueryMembershipType | undefined;

        if (!membershipRes.data && !type) return <p>Etwas lief schief...</p>;

        return (
          <MembershipPage
            type={type}
            membership={membershipRes.data ?? undefined}
            container={membershipContainer}
            parkId={parkId}
          />
        );
      }}
    </Subscribe>
  );
}

type MembershipPageProps = {
  membership?: Membership;
  type?: QueryMembershipType;
  container: MembershipDetailContainer;
  parkId: string;
};

function MembershipPage(props: MembershipPageProps) {
  const { membership, type, container, parkId } = props;

  const isInfo = (membership && isInfoMembership(membership)) || type === 'info';
  const isPark = (membership && isParkMembership(membership)) || type === 'park';

  const isNew = !membership;

  const [userId, setUserId] = React.useState(membership?.userId);
  const [infoId, setInfoId] = React.useState((membership as InfoMembership | undefined)?.infoId);

  const [validate, setValidate] = React.useState(false);

  const [parkPermissions, setParkPermissions] = React.useState<ParkPermissions | undefined>(
    membership as ParkMembership | undefined,
  );
  const [infoPermissions, setInfoPermissions] = React.useState<InfoPermissions>(
    (membership as InfoMembership | undefined)?.permissions ?? {
      canDelete: false,
      fallback: PermissionLevel.Read,
    },
  );

  const [successMessage, setSuccessMesage] = React.useState<string | undefined>(undefined);
  const successAlertProps = useSuccessAlert();

  const displaySuccessMessage = (message: string) => {
    successAlertProps.showSuccess();
    setSuccessMesage(message);
  };

  const submit = async () => {
    if (isNew) {
      if (isInfo) {
        const required = [userId, infoId, infoPermissions];

        if (required.some((v) => v === null || v === undefined)) {
          setValidate(true);
          return;
        }

        const vars: CreateInfoMembershipVars = {
          userId: userId!,
          infoId: infoId!,
          permissions: infoPermissions,
        };
        await container.create(vars);
        if (container.state.save.isFinished) {
          displaySuccessMessage('Mitgliedschaft erfolgreich erstellt.');
          const newUrl = `/park/${parkId}/memberships/${container.state.entity.data!.id}`;
          window.history.pushState('', 'Mitgliedschaft bearbeiten', newUrl);
        }
      }
      return;
    } else {
      if (isInfo) {
        const vars: UpdateInfoMembershipVars = {
          permissions: infoPermissions,
          membershipId: membership!.id,
        };
        await container.update(vars);
        if (container.state.save.isFinished)
          displaySuccessMessage('Mitgliedschaft erfolgreich aktualisiert.');
        return;
      }
    }
  };

  const loading = container.state.save.isLoading;

  const inputsDisabled = loading;

  const canSubmit = !loading && !inputsDisabled;

  return (
    <FormWrapper
      isLoading={loading}
      actionName={isNew ? 'Erstellen' : 'Speichern'}
      formTitle={isNew ? 'Mitgliedschaft erstellen' : 'Mitgliedschaft bearbeiten'}
      submit={submit}
      canSubmit={canSubmit}
      actionError={container.state.save.message}
      successAlert={!successMessage ? undefined : { ...successAlertProps, message: successMessage }}
    >
      <Grid item>
        <UserSearchInput disabled={inputsDisabled || !isNew} value={userId} onChange={setUserId} />
      </Grid>
      {isPark && (
        <>
          <Grid item>
            <ParkPermissionsInput
              onChange={setParkPermissions}
              value={parkPermissions}
              disabled={inputsDisabled}
            />
          </Grid>
        </>
      )}

      {isInfo && (
        <>
          <Grid item>
            <InfoSearchInput
              disabled={inputsDisabled || !isNew}
              value={infoId}
              onChange={setInfoId}
              helperText={'Information auf die Benutzer Zugriff hat'}
            />
          </Grid>
          <Grid item>
            <InfoPermissionsInput
              onChange={setInfoPermissions}
              value={infoPermissions}
              disabled={inputsDisabled}
            />
          </Grid>
        </>
      )}
    </FormWrapper>
  );
}

type SearchInputProps = {
  value?: string;
  onChange: (value: string | undefined) => void;
  disabled?: boolean;
  required?: boolean;
  validate?: boolean;
  helperText?: string;
};

type InfoSearchInputProps = SearchInputProps;

function InfoSearchInput(props: InfoSearchInputProps) {
  const { disabled, value, onChange, required, validate, helperText } = props;

  const displayError = required && validate && !value;

  return (
    <Subscribe to={[ParkInfosContainer]}>
      {(container) => {
        if (container.state.infos.isIdle) {
          container.load();
        }

        if (!container.state.infos.data) return <div></div>;
        const infos = container.state.infos.data ?? [];

        const info = infos.find((i) => i.id === value);

        return (
          <Autocomplete
            id="combo-box-demo"
            options={infos}
            value={info}
            getOptionLabel={(option) => option.title}
            disabled={disabled}
            fullWidth
            getOptionSelected={(option, value) => option.id === value.id}
            onChange={(_, info) => {
              onChange(info?.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={displayError ? 'Eingabe benötigt' : helperText}
                error={displayError}
                label="Info"
                variant="outlined"
              />
            )}
          />
        );
      }}
    </Subscribe>
  );
}
type UserSearchInputProps = SearchInputProps;

function UserSearchInput(props: UserSearchInputProps) {
  const { disabled, value, onChange, required, validate, helperText } = props;

  const displayError = required && validate && !value;

  return (
    <Subscribe to={[AllEditorsContainer]}>
      {(container) => {
        if (container.state.editors.isIdle) {
          container.load();
        }

        if (!container.state.editors.data) return <div></div>;
        const editors = container.state.editors.data ?? [];

        const user = editors.find((i) => i.id === value);

        return (
          <Autocomplete
            id="combo-box-demo"
            options={editors}
            value={user}
            defaultValue={user}
            getOptionLabel={(option) => option.email}
            disabled={disabled}
            fullWidth
            getOptionSelected={(option, value) => {
              return option.id === value.id;
            }}
            onChange={(_, user) => {
              onChange(user?.id);
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                helperText={displayError ? 'Eingabe benötigt' : helperText}
                error={displayError}
                label="Benutzer"
                variant="outlined"
              />
            )}
          />
        );
      }}
    </Subscribe>
  );
}

type InfoPermissionsProps = {
  disabled?: boolean;
  value: InfoPermissions;
  onChange: (permissions: InfoPermissions) => void;
};
function InfoPermissionsInput(props: InfoPermissionsProps) {
  const { value, disabled, onChange } = props;

  const [permissions, setPermissions] = React.useState(value);

  useEffect(() => {
    onChange(permissions);
  }, [permissions]);

  const permissionOrder = [PermissionLevel.None, PermissionLevel.Read, PermissionLevel.Write];
  return (
    <Grid container item xs={12} spacing={3} direction="column">
      <Grid item>
        <h3 style={{ marginBottom: 0, marginTop: 16, textAlign: 'start' }}>Berechtigungen</h3>
      </Grid>
      <Grid item xs={12} direction="row" justify="space-between" container>
        <Grid item xs direction="column">
          <h4 style={{ margin: 0, textAlign: 'start' }}>Standardberechtigung</h4>
          <p style={{ margin: 0, textAlign: 'start' }}>
            Berechtigung wird angewendet, wenn kein expliziter Wert für Eigenschaft angegeben ist.
          </p>
        </Grid>
        <Grid item>
          <FormControl>
            <InputLabel id="demo-simple-select-label">Standard</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={permissions.fallback}
              onChange={(e) => {
                setPermissions({ ...permissions, fallback: e.target.value as PermissionLevel });
              }}
            >
              {permissionOrder.map((level) => {
                return (
                  <MenuItem value={level} key={level}>
                    {getFriendlyPermissionLevel(level)}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>

      <TableContainer component={Paper}>
        <Table aria-label="simple table" size="small">
          <TableHead>
            <TableRow>
              <TableCell>Eigenschaft</TableCell>
              {permissionOrder.map((level) => {
                return <TableCell align="right">{getFriendlyPermissionLevel(level)}</TableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {InfoProperties.map((property) => {
              const properyPermissions = (permissions ?? {})[property];
              const explicitValue = properyPermissions !== undefined;

              return (
                <TableRow key={property}>
                  <TableCell component="th" scope="row">
                    {getFriendlyInfoProperty(property)}
                  </TableCell>
                  {permissionOrder.map((level) => {
                    const checked = properyPermissions === level;

                    return (
                      <TableCell align="right">
                        <Checkbox
                          disabled={disabled}
                          checked={checked}
                          onChange={(e) => {
                            var copy = { ...(permissions ?? {}) };
                            if (checked) delete copy[property];
                            else copy = { ...copy, [property]: level };

                            setPermissions(copy);
                          }}
                        />
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </Grid>
  );
}

type ParkPermissionsProps = {
  disabled?: boolean;
  value: ParkPermissions | undefined;
  onChange: (permissions: ParkPermissions) => void;
};
function ParkPermissionsInput(props: ParkPermissionsProps) {
  return <div></div>;
}
