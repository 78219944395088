import { Box, CircularProgress } from '@material-ui/core';
import { ActiveParkContainer } from 'model/parks/Active';
import { getParkInfoContainer } from 'Provider';
import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import DefaultLayout from 'ui/pages/layouts/Default';
import { ActiveParkSelectionPage } from 'ui/pages/parks/ActiveParkSelection';
import { Provider, Subscribe } from 'unstated-typescript';
import { UserContainer } from './model/LoginState';

function LoadingScreen() {
  return (
    <Box
      bgcolor="#003022"
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress style={{ color: 'white' }}></CircularProgress>
    </Box>
  );
}

export const AdminRoute = ({ component, ...rest }) =>
  RouteWrapper({ component, adminRequired: true, isPrivate: true, ...rest });

export const ParkAdminRoute = ({ component, ...rest }) =>
  RouteWrapper({
    component,
    parkAdminRequired: true,
    parkMemberRequired: true,
    isPrivate: true,
    ...rest,
  });

export const RouteWrapper = ({
  component: Component,
  isNormalRoute = false,
  isPrivate = false,
  parkMemberRequired = true,
  parkAdminRequired = false,
  adminRequired = false,
  ...rest
}: any) => {
  return (
    <Subscribe to={[UserContainer, ActiveParkContainer]}>
      {(c, activeParkContainer) => {
        const { isAuthorized } = c;

        if (c.state.setup.isIdle || c.state.setup.isLoading) {
          return <LoadingScreen />;
        }

        if (!isAuthorized) {
          /**
           * Redirect user to SignIn page if he tries to access a private route
           * without authentication.
           */
          if (isPrivate) {
            return (
              <Route
                {...rest}
                render={({ location }) => {
                  return (
                    <Redirect
                      to={{
                        pathname: '/login',
                        state: { from: location, originalLocation: location.pathname },
                      }}
                    />
                  );
                }}
              />
            );
          }
        }

        if (adminRequired && !c.state.user!.data?.admin)
          return (
            <Redirect
              to={{
                pathname: `/`,
              }}
            />
          );

        if (isNormalRoute) {
          return (
            <Route
              {...rest}
              render={(props) => {
                return (
                  <DefaultLayout>
                    <Component {...props} />
                  </DefaultLayout>
                );
              }}
            />
          );
        }

        if (!c.hasParkMembership) {
          if (parkMemberRequired || parkAdminRequired) {
            return (
              <Redirect
                to={{
                  pathname: `/infos`,
                }}
              />
            );
          }

          return (
            <Route
              {...rest}
              render={(props) => {
                return (
                  <DefaultLayout>
                    <Component {...props} />
                  </DefaultLayout>
                );
              }}
            />
          );
        }

        const { state } = activeParkContainer;

        const { activeParkId: activePark, membership } = state;
        if (activePark.isIdle) {
          window.requestAnimationFrame(() => {
            activeParkContainer.load();
          });
        }

        if (activePark.isIdle || activePark.isLoading) return <LoadingScreen />;
        return (
          <Route
            {...rest}
            render={(props) => {
              const { pathname } = props.location;
              const params = props.match.params;
              const paramsParkId = params.parkId;

              if (activePark.hasData && !activePark.data) {
                if (paramsParkId) {
                  activeParkContainer.setActivePark(paramsParkId);
                  return <LoadingScreen />;
                }

                return <ActiveParkSelectionPage />;
              }

              const activeParkId = activePark.data;

              if (!paramsParkId && activeParkId && pathname === '/') {
                return (
                  <Redirect
                    to={{
                      pathname: `/park/${activeParkId}`,
                    }}
                  />
                );
              }

              if (!paramsParkId) {
                return (
                  <DefaultLayout>
                    <Component {...props} />
                  </DefaultLayout>
                );
              }

              if (paramsParkId !== activeParkId) {
                window.requestAnimationFrame(() => {
                  activeParkContainer.setActivePark(paramsParkId);
                });
                return <LoadingScreen />;
              }

              const isLoading = membership.isIdle || membership.isLoading;
              if (isLoading) return <LoadingScreen />;

              const hasError = activePark.hasError || membership.hasError;
              if (hasError) return <p>{activePark.message ?? membership.message}</p>;

              const membershipData = membership.data;
              if (!membershipData || (parkAdminRequired && !membershipData.admin))
                return <p>Nicht berechtigt auf Park zuzugreifen.</p>;

              const container = getParkInfoContainer(activeParkId!);
              return (
                <Provider inject={[container]}>
                  <DefaultLayout>
                    <Component {...props} />
                  </DefaultLayout>
                </Provider>
              );
            }}
          />
        );
      }}
    </Subscribe>
  );
};
