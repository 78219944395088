import { FormControl, MenuItem } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import Select from '@material-ui/core/Select';
import { Languages } from 'data/entity/Park';
import React from 'react';
import { languageMap } from 'util/i18n';

export type LanguageSelectionProps = {
  current: Languages;
  disabled?: boolean;
  languages?: Languages[];
  onChange?: (language: Languages) => void;
};

export function LanguageSelection(props: LanguageSelectionProps) {
  const { current, disabled, onChange } = props;

  const languages = props.languages ?? ['de', 'en'];
  return (
    <FormControl variant="outlined">
      <InputLabel id="demo-simple-select-outlined-label">Sprache</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        required={true}
        disabled={disabled}
        labelWidth={60}
        autoWidth={true}
        onChange={(e) => {
          if (onChange) onChange(e.target.value as Languages);
        }}
        value={current}
      >
        {languages.map((language) => {
          return (
            <MenuItem key={language} value={language}>
              {languageMap[language]}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
}
