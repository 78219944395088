import firebase from 'firebase';
import { IUserRepository } from '../../data/repositories/User';
import { User } from '../../data/user/User';
import IAuthenticationService from '../../data/services/Authentication';
import { AuthToken } from '../util';
import { EventBus } from 'services/event/bus';
import { LogoutEvent } from 'services/event/events';
import logger from 'services/Logging';

const storageKey = 'UserKey';

export class FirebaseAuthService implements IAuthenticationService {
  private firebaseRestoreStatePromise: Promise<void>;

  private deferredPromise: { resolve: () => void; reject: () => void } | null = null;

  private userRepository: IUserRepository;

  constructor(userRepository: IUserRepository, private eventBus: EventBus) {
    this.userRepository = userRepository;

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        logger.setContext({
          userId: user.uid,
          email: user.email ?? undefined,
        });
      } else {
        logger.clearContext();
      }
    });

    this.firebaseRestoreStatePromise = new Promise((resolve, reject) => {
      this.deferredPromise = { resolve, reject };
    });
    this.waitForFirebase();
  }

  async logout(): Promise<void> {
    await firebase.auth().signOut();
    localStorage.clear();
    this.eventBus.publish(new LogoutEvent());
  }

  async getAuthToken(): Promise<AuthToken> {
    const current = firebase.auth().currentUser;

    const token = await current!!.getIdToken();

    return {
      token: token,
      userId: current!!.uid,
    };
  }

  async restoreSession(): Promise<User | null> {
    await this.firebaseRestoreStatePromise;
    firebase.auth().onAuthStateChanged(async (val) => {
      if (val == null) {
        localStorage.removeItem(storageKey);
        return;
      }
      const token = await val.getIdTokenResult();
      const actualUser = await this.userRepository.getUser(val.uid);
    });

    const user = await firebase.auth().currentUser;

    return user == null
      ? null
      : {
          id: user.uid,
          email: user.email!,
          admin: user.email === 'tim.sauvageot@web.de' || user.email === 'sommerk86@gmail.com',
        };
  }

  async login(email: string, password: string): Promise<User | null> {
    const result = await firebase.auth().signInWithEmailAndPassword(email, password);
    const user = result.user;
    const actualUser = await this.userRepository.getUser(user!!.uid);
    return actualUser;
  }

  /**
   * Wait until Firebase App is defined and Firebase Auth State is restored
   */
  private waitForFirebase(): void {
    const authChecker = setInterval(() => {
      if (!firebase.app()) return;

      this.waitUntilFirebaseStateRestored();
      clearInterval(authChecker);
    }, 50);
  }

  private waitUntilFirebaseStateRestored(): void {
    // Automatically call `resolveFirebaseRestoreStatePromise` if onAuthStateChanged is not called
    const maxWaitForAuthenticationMs = 2500;
    const timeout = setTimeout(() => {
      this.resolveFirebaseRestoreStatePromise();
    }, maxWaitForAuthenticationMs);

    // This will be called when the state is reset
    firebase.auth().onAuthStateChanged(() => {
      this.resolveFirebaseRestoreStatePromise();
      clearTimeout(timeout);
    });
  }

  /**
   * Resolves `firebaseRestoreStatePromise` if it exists
   */
  private resolveFirebaseRestoreStatePromise(): void {
    if (!this.deferredPromise) return;
    this.deferredPromise.resolve();
  }
}
