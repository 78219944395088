import { IUserRepository } from 'data/repositories/User';
import { Resource } from 'data/Resource';
import { User } from 'data/user/User';
import { EventBus } from 'services/event/bus';
import { LogoutEvent } from 'services/event/events';
import logger from 'services/Logging';
import { Container } from 'unstated-typescript';

export type AllEditorState = {
  editors: Resource<User[]>;
};

export class AllEditorsContainer extends Container<AllEditorState> {
  constructor(private repository: IUserRepository, private eventBus: EventBus) {
    super();

    eventBus.subscribe(LogoutEvent.Name, () => {
      this.setState({
        editors: Resource.idle<User[]>(),
      });
    });
  }

  state = {
    editors: Resource.idle<User[]>(),
  };

  async load() {
    this.setState({
      ...this.state,
      editors: Resource.loading(),
    });

    try {
      const editors = await this.repository.getEditors();

      this.setState({
        ...this.state,
        editors: Resource.success(editors),
      });
    } catch (e) {
      logger.logError(e);

      this.setState({
        ...this.state,
        editors: Resource.error('Benutzer konnten nicht geladen werden.'),
      });
    }
  }
}
