import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Select,
  TextField,
  Toolbar,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/AddCircle';
import DeleteIcon from '@material-ui/icons/Delete';
import { Editor } from '@tinymce/tinymce-react';
import { kInfoImageMaxSize } from 'config';
import {
  Info,
  InfoActionType,
  InfoType,
  InfoTypes,
  LanguageSpecificInfos,
  mapToString,
} from 'data/entity/Info';
import {
  InfoMembership,
  InfoPermissions,
  InfoProperty,
  isParkMembership,
  PermissionLevel,
  permissionsFromMembership,
} from 'data/entity/Membership';
import { GpsPosition, Park } from 'data/entity/Park';
import { DeleteInfoContainer } from 'model/info/Delete';
import {
  CreateVariables,
  InfoActionInput as InfoActionInputModel,
  InfoDetailsContainer,
  UpdateVariables,
} from 'model/info/Detail';
import { UserContainer } from 'model/LoginState';
import { ActiveParkContainer } from 'model/parks/Active';
import {
  getDeleteInfoContainer,
  getInfoDetailsContainer,
  getParkDetailsContainer,
  getParkInfoContainer,
} from 'Provider';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { UnauthorizedScreen } from 'ui/components/auth/Unauthorized';
import AlertDialog from 'ui/components/form/AlertDialog';
import { FormActionError, SuccessAlert, useSuccessAlert } from 'ui/components/form/feedback';
import { DisplayImage } from 'ui/components/form/image';
import { LocationSelection } from 'ui/components/forms/Location';
import { InfoActionInput } from 'ui/components/info/action';
import { TagsInput } from 'ui/components/info/tags';
import { Provider, Subscribe } from 'unstated-typescript';
import { distance } from 'util/distance';
import { languageMap } from 'util/i18n';
import { v4 as uuidv4 } from 'uuid';
import './detail.css';

export function InfoDetails() {
  const params = useParams<{ infoId?: string; parkId: string }>();

  return (
    <Subscribe to={[UserContainer, ActiveParkContainer]}>
      {(userContainer, activeParkContainer) => {
        const memberships = userContainer.state.memberships.data ?? [];

        if (memberships.length === 0) {
          return <UnauthorizedScreen />;
        }

        const membership = memberships.find((m) =>
          isParkMembership(m)
            ? m.parkId === params.parkId
            : (m as InfoMembership).infoId === params.infoId,
        );
        if (!membership) {
          return <UnauthorizedScreen />;
        }

        const container = getInfoDetailsContainer();
        if (params.infoId) container.load(params.infoId);

        const parkContainer = getParkDetailsContainer();
        if (activeParkContainer.state.park.data) {
          parkContainer.setPark(activeParkContainer.state.park.data);
        } else {
          parkContainer.load(params.parkId);
        }

        return (
          <Subscribe to={[container, parkContainer, getParkInfoContainer(params.parkId)]}>
            {(container, parkContainer, parkInfosContainer) => {
              const { info: infoRes } = container.state;

              if (infoRes.isLoading) return <CircularProgress />;

              if (infoRes.hasError) return <p>Info konnte nicht geladen werden.</p>;

              if (infoRes.hasData && !infoRes.data) {
                return <p>Info existiert nicht.</p>;
              }

              if (!parkContainer.state.park.hasData) return <CircularProgress />;

              const info = infoRes.data;

              const infoPermissions = permissionsFromMembership(membership);
              return (
                <InfoForm
                  info={info ?? undefined}
                  parkInfos={parkInfosContainer.state.infos.data ?? []}
                  container={container}
                  park={parkContainer.state.park.data!}
                  permissions={infoPermissions}
                />
              );
            }}
          </Subscribe>
        );
      }}
    </Subscribe>
  );
}

type InputFormProps = {
  info?: Info;
  park: Park;
  parkInfos: Info[];
  container: InfoDetailsContainer;
  permissions: InfoPermissions;
};

function InfoForm(props: InputFormProps) {
  const { info, container, park, parkInfos, permissions } = props;

  const params = useParams<{ parkId: string }>();
  const history = useHistory();

  const inputsEnabled = true;
  const [successMessage, setSuccessMesage] = React.useState('');
  const successAlertProps = useSuccessAlert();

  const [name, setName] = React.useState(info?.title ?? '');
  const [infoType, setInfoType] = React.useState(info?.infoType ?? InfoType.Stature);
  const [previewImage, setPreviewImage] = React.useState<File | null | undefined>(undefined);
  const [position, setPosition] = React.useState<GpsPosition | undefined>(info?.position);

  const [validImage, setValidImage] = React.useState(true);
  const [imageUrl, setImageUrl] = React.useState(info?.titleImageUrl ?? undefined);
  const [imageAuthor, setImageAuthor] = React.useState(info?.imageAuthor ?? undefined);
  const [tags, setTags] = React.useState<string[]>(info?.tags ?? []);
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState<string | undefined>(undefined);
  const completed = info?.completed ?? false;

  const [validate, setValidate] = React.useState(false);

  const [hideImageAuthor, setHideImageAuthor] = React.useState(info?.hideImageAuthor ?? false);
  const [infoActions, setInfoActions] = React.useState<InfoActionInputModel[]>(
    info?.actions?.map((a) => ({ ...a, files: {} })) ?? [],
  );

  const isNewInfo = info === undefined;

  const formTitle = isNewInfo ? 'Neue Info' : 'Info bearbeiten';

  const canSubmit = !isLoading;

  const actionName = isNewInfo ? 'Erstellen' : 'Speichern';

  const contentLanguageKeys = Object.keys(info?.content ?? {});
  const descriptions: Record<string, DescriptionContent> = {};

  contentLanguageKeys.forEach((key) => {
    const content = info!.content[key];
    const value: DescriptionContent = {
      short: content?.shortDescription,
      long: content?.fullDescription,
    };
    descriptions[key] = value;
  });

  const [languageContent, setLanguageContent] = React.useState(descriptions);

  const submit = async (e) => {
    e.preventDefault();

    const isNewInfo = !info;

    if (!validImage) {
      setError('Bitte behebe alle Fehler mit dem Vorschaubild.');
      return;
    }

    setError(undefined);

    if (!name || name.trim() === '') {
      setError('Bitte einen Titel angeben.');
      return;
    }

    const content: Record<string, LanguageSpecificInfos> = {};

    if (!position) {
      setError('Der Standort muss gesetzt sein.');
      return;
    }

    const titleExists =
      parkInfos.find(
        (i) =>
          infoType !== InfoType.Bin &&
          i.title === name &&
          i.id !== info?.id &&
          distance(i.position, position) < 100,
      ) !== undefined;
    if (titleExists) {
      setError('Der Name ist bereits vergeben.');
      return;
    }

    if (completed) {
      const germanShortDescription = languageContent['de']?.short;
      if (!germanShortDescription || germanShortDescription.trim() === '') {
        setError(
          'Deutsche Kurzbeschreibung muss ausgefüllt sein, damit Sehenswürdigkeit als vollständig markiert werden kann.',
        );
        return;
      }
      const englishShortDescription = languageContent['en']?.short;
      if (!englishShortDescription || englishShortDescription.trim() === '') {
        setError(
          'Englische Kurzbeschreibung muss ausgefüllt sein, damit Sehenswürdigkeit als vollständig markiert werden kann.',
        );
        return;
      }
    }
    var hasActionError = false;

    infoActions.forEach((action) => {
      const validName = action.name.trim() !== '';
      const germanLabel = action.label['de'];
      const hasGermanLabel = germanLabel !== undefined && germanLabel.trim() !== '';
      const hasGermanFile =
        (action?.urls ?? {})['de'] !== undefined || action.files['de'] !== undefined;
      const valid = validName && hasGermanFile && hasGermanLabel;

      if (!valid) {
        hasActionError = true;
        setValidate(true);
        return;
      }
    });

    if (hasActionError) return;

    Object.keys(languageContent).forEach((language) => {
      const languageValues = languageContent[language];
      if (!languageValues) return;

      const { short: shortDescription, long: fullDescription } = languageValues;
      content[language] = {
        shortDescription: shortDescription ?? '',
        fullDescription: fullDescription ?? '',
      };
    });

    setLoading(true);

    const imageAuthorName =
      imageAuthor === undefined
        ? undefined
        : imageAuthor.trim() === ''
        ? undefined
        : imageAuthor.trim();

    if (isNewInfo) {
      const variables: CreateVariables = {
        title: name,
        type: infoType,
        tags,
        parkId: params.parkId,
        content,

        completed,
        titleImage: previewImage,
        position,
        actions: infoActions,
        hideImageAuthor,
        imageAuthor: imageAuthorName,
      };

      await container.create(variables);
    } else {
      const variables: UpdateVariables = {
        id: info!.id,
        title: name,
        type: infoType,
        tags,
        parkId: params.parkId,
        content,
        completed,
        titleImage: previewImage,
        position,
        hideImageAuthor,
        actions: infoActions,
        imageAuthor: imageAuthorName,
      };

      await container.update(variables);
    }

    setLoading(false);
    const {
      saveOperation: { hasError, message, isFinished },
    } = container.state;
    if (hasError) {
      setError(message);
      return;
    }

    const feedbackMessage = isNewInfo
      ? 'Information erfolgreich erstellt.'
      : 'Information aktualisiert.';
    successAlertProps.showSuccess();
    setSuccessMesage(feedbackMessage);

    if (!isNewInfo) return;

    if (isFinished) {
      const info = container.state.info.data;

      const newUrl = `/park/${params.parkId}/infos/${info!.id}`;
      window.history.pushState('', 'Info bearbeiten', newUrl);
    }
  };

  const titlePermission = permissions['title'] ?? permissions.fallback;
  const typePermission = permissions['infoType'] ?? permissions.fallback;
  const tagsPermissions = permissions['tags'] ?? permissions.fallback;
  const contentPermission = permissions['content'] ?? permissions.fallback;
  const imagePermissions = permissions['image'] ?? permissions.fallback;
  const locationPermissions = permissions['location'] ?? permissions.fallback;
  const actionPermissions = permissions['actions'] ?? permissions.fallback;

  const canSave =
    permissions.fallback === PermissionLevel.Write ||
    Object.keys(permissions).some(
      (property) => permissions[(property as unknown) as InfoProperty] === PermissionLevel.Write,
    );

  return (
    <Grid spacing={3} item xs={12} sm={12} md={11} lg={7}>
      <Grid item xs={12}>
        <Paper>
          <Toolbar>
            <Grid item container xs={12} justify="space-between" alignItems="center">
              <h1>{formTitle}</h1>
              {!isNewInfo && (
                <Provider inject={[getDeleteInfoContainer()]}>
                  <Subscribe to={[ActiveParkContainer, DeleteInfoContainer]}>
                    {(activeParkContainer, deleteInfoContainer) => {
                      const membership = activeParkContainer.state.membership.data;
                      if (!membership) return <div></div>;

                      if (!membership.write) return <div></div>;

                      return (
                        <AlertDialog
                          title="Info löschen"
                          positiveAction="Löschen"
                          contentText={`Soll ${name} wirklich gelöscht werden?`}
                          action={async () => {
                            await deleteInfoContainer.delete(info!.id);
                            history.push(`/park/${params.parkId}/infos`);
                            return { success: true };
                          }}
                          builder={(setOpen) => {
                            return (
                              <IconButton
                                aria-label="delete"
                                onClick={() => {
                                  setOpen(true);
                                }}
                              >
                                <DeleteIcon />
                              </IconButton>
                            );
                          }}
                        />
                      );
                    }}
                  </Subscribe>
                </Provider>
              )}
            </Grid>
          </Toolbar>

          <form
            onSubmit={submit}
            onKeyPress={(event) => {
              if (event.which === 13 /* Enter */) {
                event.preventDefault();
              }
            }}
          >
            <Box p={2}>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <TextField
                    fullWidth={true}
                    required={true}
                    disabled={!inputsEnabled || titlePermission === PermissionLevel.Read}
                    id="outlined-basic"
                    label="Titel"
                    variant="outlined"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  {typePermission !== PermissionLevel.None && (
                    <InfoTypeSelection
                      value={infoType}
                      disabled={!inputsEnabled || typePermission === PermissionLevel.Read}
                      onChange={setInfoType}
                    />
                  )}
                </Grid>

                {tagsPermissions !== PermissionLevel.None && (
                  <Grid item xs={12}>
                    <TagsInput
                      tags={tags}
                      hideText={tagsPermissions === PermissionLevel.Read}
                      disabled={!inputsEnabled || tagsPermissions === PermissionLevel.Read}
                      onChange={setTags}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <h4 style={{ textAlign: 'left' }}>Vorschaubild</h4>
                  <DisplayImage
                    url={imageUrl}
                    disabled={!inputsEnabled || !(imagePermissions === PermissionLevel.Write)}
                    maxSize={kInfoImageMaxSize}
                    onChange={(file, isValid, url) => {
                      setPreviewImage(file);
                      setValidImage(isValid);
                      setImageUrl(url);
                    }}
                  />
                  {imageUrl && (
                    <Grid container justify="flex-start">
                      <Grid item>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={hideImageAuthor}
                              onChange={(e) => {
                                setHideImageAuthor(e.target.checked);
                              }}
                              name="checkedB"
                              color="primary"
                            />
                          }
                          label="Bildauthor verstecken"
                        />
                      </Grid>
                      <Grid item xs>
                        <TextField
                          fullWidth={true}
                          required={false}
                          disabled={!inputsEnabled || imagePermissions !== PermissionLevel.Write}
                          id="outlined-basic"
                          label="Bildauthor"
                          variant="outlined"
                          value={imageAuthor}
                          onChange={(e) => {
                            setImageAuthor(e.target.value);
                          }}
                        />
                      </Grid>
                    </Grid>
                  )}
                </Grid>

                {locationPermissions !== PermissionLevel.None && (
                  <Grid item xs={12}>
                    <h4 style={{ textAlign: 'left' }}>Standort</h4>
                    <LocationSelection
                      height={500}
                      initialCameraPosition={park.location}
                      initialPosition={position}
                      disabled={!inputsEnabled || !(locationPermissions === PermissionLevel.Write)}
                      onChange={(position) => {
                        setPosition(position);
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={12}>
                  <LanguageContent
                    supportedLanguages={['de', 'en']}
                    disabled={!inputsEnabled || !(contentPermission === PermissionLevel.Write)}
                    values={languageContent}
                    onChange={(values) => {
                      setLanguageContent(values);
                    }}
                  />
                </Grid>

                <Grid item xs={12} container spacing={2}>
                  <Grid item container justify="space-between">
                    <Grid>
                      <h3 style={{ textAlign: 'left', margin: '0' }}>Aktionen</h3>
                      <p style={{ margin: '0' }}>
                        Individuelle Aktionen, die in Detailansicht von Information angezeigt
                        werden.
                      </p>
                    </Grid>

                    {infoActions.length < 1 && actionPermissions === PermissionLevel.Write && (
                      <IconButton
                        onClick={() => {
                          const newAction: InfoActionInputModel = {
                            id: uuidv4(),
                            type: InfoActionType.File,
                            label: {},
                            name: '',
                            urls: {},
                            files: {},
                          };
                          setInfoActions([...infoActions, newAction]);
                        }}
                      >
                        <AddIcon />
                      </IconButton>
                    )}
                  </Grid>

                  {actionPermissions !== PermissionLevel.None && (
                    <Grid item container xs={12} spacing={3}>
                      {infoActions.map((infoAction) => {
                        return (
                          <InfoActionInput
                            validate={validate}
                            key={infoAction.id}
                            item={infoAction}
                            disabled={
                              !inputsEnabled || !(actionPermissions === PermissionLevel.Write)
                            }
                            onChange={(item) => {
                              const index = infoActions.findIndex((i) => i.id === item.id);
                              infoActions[index] = item;
                            }}
                            onDelete={() => {
                              setInfoActions(infoActions.filter((i) => i.id !== infoAction.id));
                            }}
                          />
                        );
                      })}
                    </Grid>
                  )}
                </Grid>
              </Grid>

              {error && <FormActionError message={error} />}

              {canSave && (
                <Box mt={2}>
                  <Button variant="contained" color="primary" type="submit" disabled={!canSubmit}>
                    {isLoading ? (
                      <CircularProgress style={{ color: 'white' }} size={25} />
                    ) : (
                      actionName
                    )}
                  </Button>
                </Box>
              )}
            </Box>
          </form>
        </Paper>
      </Grid>
      <SuccessAlert {...successAlertProps} message={successMessage} />
    </Grid>
  );
}

type InfoTypeSelectionProps = {
  value: InfoType;
  onChange: (value: InfoType) => void;
  disabled?: boolean;
};
function InfoTypeSelection(props: InfoTypeSelectionProps) {
  const { value, onChange } = props;

  const disabled = props.disabled ?? false;

  return (
    <FormControl variant="outlined">
      <InputLabel id="demo-simple-select-outlined-label">Typ</InputLabel>
      <Select
        labelId="demo-simple-select-outlined-label"
        id="demo-simple-select-outlined"
        required={true}
        labelWidth={30}
        autoWidth={true}
        disabled={disabled}
        value={value}
        onChange={(e) => {
          onChange(e.target.value as InfoType);
        }}
      >
        {InfoTypes.map((infoType) => {
          return <MenuItem value={infoType}>{mapToString(infoType)}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
}

type DescriptionContent = { short?: string; long?: string };
type LanguageContentProps = {
  supportedLanguages: [string, ...string[]];
  values: Record<string, DescriptionContent>;
  onChange: (value: Record<string, DescriptionContent>) => void;
  disabled?: boolean;
};

const editorStyles = makeStyles((theme) => ({
  root: {
    '& >.tox-statusbar': { display: 'none' },
  },
}));

function LanguageContent(props: LanguageContentProps) {
  const { supportedLanguages, values } = props;
  const disabled = props.disabled ?? false;
  const classes = editorStyles();
  const [activeLanguage, setActiveLanguage] = React.useState(supportedLanguages[0]);

  const shortDescription = values[activeLanguage]?.short ?? '';
  const description = values[activeLanguage]?.long ?? '';
  return (
    <Box>
      <Box mt={2} mb={2}>
        <Divider />
      </Box>
      <Grid item xs={12}>
        <Grid container direction="row" justify="space-between" alignItems="flex-start">
          <h3 style={{ textAlign: 'left', margin: '0' }}>Inhalte</h3>
          <FormControl variant="outlined">
            <InputLabel id="demo-simple-select-outlined-label">Sprache</InputLabel>
            <Select
              labelId="demo-simple-select-outlined-label"
              id="demo-simple-select-outlined"
              required={true}
              labelWidth={60}
              autoWidth={true}
              onChange={(e) => {
                setActiveLanguage(e.target.value as string);
              }}
              value={activeLanguage}
            >
              {supportedLanguages.map((language) => {
                return (
                  <MenuItem key={language} value={language}>
                    {languageMap[language]}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <h4 style={{ textAlign: 'left' }}>Kurzbeschreibung</h4>
        <TextField
          variant="outlined"
          rows={3}
          value={shortDescription}
          multiline={true}
          fullWidth={true}
          disabled={disabled}
          onChange={(e) => {
            const activeLanguageValues = {
              short: e.target.value,
              long: props.values[activeLanguage]?.long,
            };
            const newProps = { ...props.values, [activeLanguage]: activeLanguageValues };
            props.onChange(newProps);
          }}
        />
      </Grid>
      <Grid item xs={12} className={classes.root}>
        <h4 style={{ textAlign: 'left' }}>Beschreibung</h4>
        <Editor
          value={description}
          apiKey="wrgx1ffz0ild9ho5ww1u4ir7mkyysg1w2uvemg9921k8sxkr"
          initialValue={description}
          disabled={disabled}
          init={{
            height: 300,
            menubar: false,
            resize: false,
            entity_encoding: 'raw',
            plugins: [
              'advlist autolink lists link image charmap print preview anchor',
              'searchreplace visualblocks code fullscreen',
              'insertdatetime media table paste code help wordcount',
            ],
            toolbar: 'undo redo | formatselect | bold italic ',
          }}
          onEditorChange={(a) => {
            const activeLanguageValues = {
              long: a,
              short: props.values[activeLanguage]?.short,
            };
            const newProps = { ...props.values, [activeLanguage]: activeLanguageValues };
            props.onChange(newProps);
          }}
        />
      </Grid>
    </Box>
  );
}
