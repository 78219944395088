import { State } from 'data/enum';
import packageJson from '../package.json';
export type Stage = 'dev' | 'prod';

export const States = [
  State.BadenWuerttemberg,
  State.Bayern,
  State.Berlin,
  State.Brandenburg,
  State.Bremen,
  State.Hamburg,
  State.Hessen,
  State.MecklenburgVorpommern,
  State.Niedersachen,
  State.NRW,
  State.RheinlandPfalz,
  State.Saarland,
  State.Sachsen,
  State.SachsenAnhalt,
  State.SchleswigHolstein,
  State.Thueringen,
];

export const stage: Stage = process.env.REACT_APP_STAGE === 'prod' ? 'prod' : 'dev';

export const IS_PRODUCTION = stage === 'prod';

export const STATES = States;

export const kSupportedLanguages = ['de', 'en'];
export const kDefaultMapPosition = { lat: 52.4282788, lng: 12.8873296 };

// 500kb
export const kInfoImageMaxSize = 500 * 1024;

// 6MB
export const kInfoActionMaxFileSize = 1000 * 6 * 1024;

export const APP_VERSION = packageJson.version;
