import app from 'firebase/app';
import firebase from 'firebase';
import { stage } from 'config';
const firebaseProdConfig = {
  apiKey: 'AIzaSyBNsV_DzFadtrmLchWZ9Xss1lxQPvZGET0',
  authDomain: 'outdoorguide.firebaseapp.com',
  databaseURL: 'https://outdoorguide.firebaseio.com',
  projectId: 'outdoorguide',
  storageBucket: 'outdoorguide.appspot.com',
  messagingSenderId: '94243921499',
  appId: '1:94243921499:web:2787f3a4f7ebf56447e97e',
};

const firebaseDevConfig = {
  apiKey: 'AIzaSyBRoCQn2vZw2vD8G5YN9IwbrxPHRNAgrZw',
  authDomain: 'outdoordev-c3fd9.firebaseapp.com',
  databaseURL: 'https://outdoordev-c3fd9.firebaseio.com',
  projectId: 'outdoordev-c3fd9',
  storageBucket: 'outdoordev-c3fd9.appspot.com',
  messagingSenderId: '139303250238',
  appId: '1:139303250238:web:e9b832664a2b3b9155646f',
};

const isProduction = stage === 'prod';

const config = isProduction ? firebaseProdConfig : firebaseDevConfig;
class Firebase {
  constructor() {
    app.initializeApp(config);
    app.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
  }
}
export default Firebase;
