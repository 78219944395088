export enum State {
  BadenWuerttemberg = 0,
  Bayern = 1,
  Berlin = 2,
  Brandenburg = 3,
  Bremen = 4,
  Hamburg = 5,
  Hessen = 6,
  MecklenburgVorpommern = 7,
  Niedersachen = 8,
  NRW = 9,
  RheinlandPfalz = 10,
  Saarland = 11,
  Sachsen = 12,
  SachsenAnhalt = 13,
  SchleswigHolstein = 14,
  Thueringen = 15,
}
