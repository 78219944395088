import { InfoProperty, PermissionLevel } from 'data/entity/Membership';

export const languageMap = {
  de: 'Deutsch',
  en: 'Englisch',
};

export function getFriendlyPermissionLevel(level: PermissionLevel) {
  return {
    [PermissionLevel.None]: 'Versteckt',
    [PermissionLevel.Read]: 'Lesen',
    [PermissionLevel.Write]: 'Bearbeiten',
  }[level]!;
}

export function getFriendlyInfoProperty(property: InfoProperty) {
  switch (property) {
    case InfoProperty.Actions:
      return 'Aktionen';
    case InfoProperty.Content:
      return 'Inhalte';
    case InfoProperty.Image:
      return 'Vorschaubild';
    case InfoProperty.InfoType:
      return 'Typ';
    case InfoProperty.Location:
      return 'Standort';
    case InfoProperty.Tags:
      return 'Tags';
    case InfoProperty.Title:
      return 'Titel';
  }

  return '-';
}
