import { ParkMembership } from 'data/entity/Membership';
import { Park } from 'data/entity/Park';
import { MembershipRepository } from 'data/repositories/Membership';
import { ParkRepository } from 'data/repositories/Park';
import { Resource } from 'data/Resource';
import { EventBus } from 'services/event/bus';
import { LogoutEvent } from 'services/event/events';
import logger from 'services/Logging';
import { Container } from 'unstated-typescript';

export type ActiveParkState = {
  activeParkId: Resource<string | null>;
  membership: Resource<ParkMembership | null>;
  park: Resource<Park>;
};

export class ActiveParkContainer extends Container<ActiveParkState> {
  constructor(
    private repository: ParkRepository,
    private membershipRepository: MembershipRepository,
    private getUserId: () => Promise<string>,
    eventBus: EventBus,
  ) {
    super();

    eventBus.subscribe(LogoutEvent.Name, () => {
      this.setState({
        activeParkId: Resource.idle<string>(),
        membership: Resource.idle<ParkMembership>(),
        park: Resource.idle<Park>(),
      });
    });
  }

  state = {
    activeParkId: Resource.idle<string>(),
    membership: Resource.idle<ParkMembership>(),
    park: Resource.idle<Park>(),
  };

  async setActivePark(parkId: string) {
    try {
      localStorage.setItem('activeParkId', parkId);
      this.setState({
        ...this.state,
        membership: Resource.loading(),
        activeParkId: Resource.loading(),
      });

      const userId = await this.getUserId();
      const membership = await this.membershipRepository.findUserParkMembership({ userId, parkId });
      const park = await this.repository.findById(parkId);
      this.setState({
        ...this.state,
        activeParkId: Resource.success(parkId),
        park: Resource.success(park!),
        membership: Resource.success(membership ?? null),
      });
    } catch (e) {
      logger.logError(e);
      this.setState({
        ...this.state,
        membership: Resource.error('Parkberechtigungen konnten nicht geladen werden.'),
      });
    }
  }

  async load() {
    this.setState({
      ...this.state,
      activeParkId: Resource.loading(),
    });

    try {
      const parkId = localStorage.getItem('activeParkId');
      if (parkId) {
        await this.setActivePark(parkId);
        return;
      }
      this.setState({
        ...this.state,
        activeParkId: Resource.success(parkId),
      });
    } catch (e) {
      logger.logError(e);

      this.setState({
        ...this.state,
        activeParkId: Resource.error('Parkanlagen konnten nicht geladen werden.'),
      });
    }
  }
}
