import * as Sentry from '@sentry/react';
import { IS_PRODUCTION } from 'config';

export type LoggerEvent = {
  message: string;
};

export type LoggerContext = {
  userId: string;
  email: string | undefined;
};

export interface Logger {
  logError(error: unknown);

  logEvent(event: LoggerEvent);

  setContext(context: LoggerContext);

  clearContext();
}

class ProductionLogger implements Logger {
  setContext(context: LoggerContext) {
    Sentry.configureScope((scope) => {
      scope.setUser({
        id: context.userId,
        email: context.email,
      });
    });
  }
  clearContext() {
    Sentry.configureScope((scope) => {
      scope.setUser(null);
    });
  }
  logEvent(event: LoggerEvent) {
    Sentry.captureEvent({
      message: event.message,
    });
  }

  logError(error: any) {
    Sentry.captureException(error);
  }
}

class DevLogger implements Logger {
  setContext(context: LoggerContext) {
    console.log({ context });
  }
  clearContext() {
    console.log('clear context');
  }

  logError(error: unknown) {
    console.error(error);
  }
  logEvent(event: LoggerEvent) {
    console.log(event);
  }
}

const logger = IS_PRODUCTION ? new ProductionLogger() : new DevLogger();

export default logger;
