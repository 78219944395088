import { User } from 'data/user/User';
import firebase from 'firebase';

export const membershipCollection = 'memberships';

export async function getUser(id: string): Promise<User | null> {
  const doc = await firebase.firestore().collection('user').doc(id).get();
  if (!doc.exists) return null;

  const data = doc.data();
  if (!data) return null;

  return userFromDocument(doc);
}

export function removeNotExisting<T extends { exists: boolean }>(docs: T[]): T[] {
  return docs.filter((doc) => doc.exists);
}

export function userFromDocument(doc: firebase.firestore.DocumentData): User {
  const data = doc.data();
  const user: User = {
    id: doc.id,
    email: data.email,
    admin: data.email === 'tim.sauvageot@web.de' || data.email === 'sommerk86@gmail.com',
  };
  return user;
}
