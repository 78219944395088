import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Collapse from '@material-ui/core/Collapse';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import FormControl from '@material-ui/core/FormControl';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme, withStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import ExitToApp from '@material-ui/icons/ExitToApp';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import MapIcon from '@material-ui/icons/Map';
import BookIcon from '@material-ui/icons/Book';
import CreateIcon from '@material-ui/icons/Create';
import { UserContainer } from 'model/LoginState';
import { ActiveParkContainer } from 'model/parks/Active';
import { AllParksContainer } from 'model/parks/All';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { Subscribe } from 'unstated-typescript';
import GroupIcon from '@material-ui/icons/Group';
import packageJson from '../../../../package.json';
import { ParkMembershipsBuilder, RequiresParkMembership } from 'ui/components/auth/Membership';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerContainer: {
      overflow: 'auto',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
  }),
);

type DrawerIcon = {
  name: string;
  link: string;
  icon: any;
  sublist?: DrawerIcon[];
  requiresPark?: boolean;
};

const icons: DrawerIcon[] = [
  {
    name: 'Karte',
    link: '/',
    icon: <MapIcon />,
  },
  {
    name: 'Infos',
    link: '/infos',
    icon: <BookIcon />,
    requiresPark: false,
  },
  {
    name: 'Details',
    link: '/details',
    icon: <CreateIcon />,
  },
  {
    name: 'Mitglieder',
    link: '/members',
    icon: <GroupIcon />,
  },
];

function MyAppBar() {
  const classes = useStyles();
  return (
    <AppBar position="fixed" className={classes.appBar}>
      <Toolbar>
        <Grid container xs={12} justify="space-between">
          <Grid item xs={9} container alignItems="center">
            <Box mr={1}>
              <img src="/logo.png" height={20} />
            </Box>
            <Typography variant="h6" noWrap>
              HistoryGo Admin
            </Typography>
            <Box ml={10}>
              <ParkSelectionWithStyles />
            </Box>
          </Grid>

          <Box>
            <Subscribe to={[UserContainer]}>
              {(container) => {
                return (
                  <IconButton
                    aria-label="delete"
                    onClick={() => {
                      container.logout();
                    }}
                  >
                    <ExitToApp style={{ fill: 'white' }} />
                  </IconButton>
                );
              }}
            </Subscribe>
          </Box>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}

const navigationStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

export function NavItem(props: { item: DrawerIcon; nested?: boolean }) {
  const { item, nested } = props;
  const classes = navigationStyles();
  const history = useHistory();

  const getKey = (): string => 'NavBarItem' + item.name;

  const storageKey = getKey();
  const storageItem = localStorage.getItem(storageKey);
  var visibility = true;
  if (storageItem) {
    const visible = JSON.parse(storageItem).visible as boolean;
    visibility = visible;
  }

  const [open, setOpen] = React.useState(visibility);
  const handleClick = () => {
    const visible = open;
    setOpen(!visible);
    localStorage.setItem(storageKey, JSON.stringify({ visible: !visible }));
  };

  return (
    <Subscribe to={[ActiveParkContainer]}>
      {(c) => {
        const activeParkId = c.state.activeParkId.data;

        return (
          <>
            <ListItem
              button
              className={nested ? classes.nested : undefined}
              key={item.name}
              onClick={(e) => {
                if (!item.sublist) {
                  const path = activeParkId ? `/park/${activeParkId}${item.link}` : item.link;
                  history.push(path);
                } else {
                  handleClick();
                }
              }}
            >
              <ListItemIcon>{item.icon}</ListItemIcon>
              <ListItemText primary={item.name} />
              {!item.sublist ? undefined : open ? <ExpandLess /> : <ExpandMore />}
            </ListItem>
            {item.sublist && (
              <Collapse in={open} timeout="auto" unmountOnExit>
                <List component="div" disablePadding>
                  {item.sublist.map((item) => (
                    <NavItem item={item} nested={true} />
                  ))}
                </List>
              </Collapse>
            )}
          </>
        );
      }}
    </Subscribe>
  );
}

export default function DefaultDrawer({ children }: { children: any }) {
  const classes = useStyles();
  const navigationClasses = navigationStyles();
  const [opened, setOpen] = React.useState<string[]>([]);

  const toggleList = (name: string) => {
    const itemOpen = isOpen(name);
    if (itemOpen) setOpen(opened.filter((i) => i !== name));
    else setOpen([...opened, name]);
  };

  const isOpen = (name: string) => opened.includes(name);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <MyAppBar />
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
      >
        <Toolbar />
        <div style={{ height: '100%', position: 'relative' }}>
          <div className={classes.drawerContainer}>
            <ParkMembershipsBuilder>
              {(memberships) => {
                const items = icons.filter((icon) => {
                  const requiresPark = icon.requiresPark ?? true;
                  if (!requiresPark) return true;

                  return (
                    (requiresPark && memberships.length > 0) ||
                    (!requiresPark && memberships.length === 0)
                  );
                });

                return (
                  <List>
                    {items.map((item: DrawerIcon) => {
                      return <NavItem item={item} />;
                    })}
                  </List>
                );
              }}
            </ParkMembershipsBuilder>
          </div>

          <p style={{ position: 'absolute', bottom: 0, width: '100%' }}>
            Version {packageJson.version}
          </p>
        </div>
      </Drawer>
      <main className={classes.content}>
        <Toolbar />
        {children}
      </main>
    </div>
  );
}

const useOutlinedInputStyles = makeStyles((theme) => ({
  root: {
    '& $notchedOutline': {
      borderColor: 'white',
    },
    '&:hover $notchedOutline': {
      borderColor: 'grey',
    },
    '&$focused $notchedOutline': {
      borderColor: 'primary',
    },
  },
  focused: {},
  notchedOutline: {},
}));

const parkSelectionStyles = (theme) => ({
  select: {
    color: 'white',
    borderColor: 'white',
    '&:before': {
      borderColor: 'white',
    },
    '&:after': {
      borderColor: 'white',
    },
  },
  icon: {
    fill: 'white',
  },
});

const ParkSelection = ({ classes }) => {
  const outlinedInputClasses = useOutlinedInputStyles();
  const history = useHistory();
  return (
    <RequiresParkMembership>
      <Subscribe to={[ActiveParkContainer]}>
        {(activeParkContainer: ActiveParkContainer) => {
          const activeParkResource = activeParkContainer.state.activeParkId;
          const { isLoading, isIdle, data } = activeParkResource;
          if (isIdle) {
            activeParkContainer.load();
          }

          const changeActivePark = (id) => {
            if (id === activeParkResource.data) return;

            if (id === 'new') {
              history.push('/park');
              return;
            }

            const pathParts = history.location.pathname.split('/');
            pathParts[2] = id;
            const restoredPath = pathParts.join('/');
            history.push(restoredPath);
          };

          const activeParkId = data;

          return (
            <Subscribe to={[AllParksContainer]}>
              {(parksContainer) => {
                const parksResource = parksContainer.state.parks;
                const { isIdle, isLoading } = parksResource;
                if (isIdle) {
                  parksContainer.load();
                }

                if (isLoading) {
                  return <CircularProgress style={{ color: '#ffffff' }} size={30} />;
                }

                const parks = parksContainer.state.parks.data ?? [];

                const items = parks.map((park) => {
                  return <MenuItem value={park.id}>{park.displayName}</MenuItem>;
                });

                const userIsAdmin = true;
                if (userIsAdmin) {
                  items.push(<MenuItem value={'new'}>Neuer Park</MenuItem>);
                }

                return (
                  <FormControl variant="outlined" classes={classes}>
                    <Select
                      labelId="demo-simple-select-outlined-label"
                      id="demo-simple-select-outlined"
                      autoWidth={true}
                      inputProps={{
                        classes: {
                          notchedOutline: classes.notchedOutline,
                        },
                      }}
                      input={<OutlinedInput classes={outlinedInputClasses} />}
                      value={activeParkId}
                      style={{ borderColor: 'white' }}
                      classes={classes}
                      onChange={(e) => {
                        changeActivePark(e.target.value);
                      }}
                    >
                      {items}
                    </Select>
                  </FormControl>
                );
              }}
            </Subscribe>
          );
        }}
      </Subscribe>
    </RequiresParkMembership>
  );
};

const ParkSelectionWithStyles = withStyles(parkSelectionStyles)(ParkSelection);
