import React from 'react';
import { Center } from '../util/Center';

export function UnauthorizedScreen() {
  return (
    <div>
      <Center child={<p>Du bist nicht authorisiert auf diese Seite zuzugreifen.</p>}></Center>
    </div>
  );
}
