import { Info } from 'data/entity/Info';
import { InfoRepository } from 'data/repositories/Info';
import { Resource } from 'data/Resource';
import { EventBus } from 'services/event/bus';
import { InfoUpdatedEvent } from 'services/event/events';
import logger from 'services/Logging';
import { Container } from 'unstated-typescript';

export type UserInfoState = {
  infos: Resource<Info[]>;
};

export class UserInfoContainer extends Container<UserInfoState> {
  constructor(
    private repository: InfoRepository,
    private eventBus: EventBus,
    private infoIds: string[],
  ) {
    super();

    if (!eventBus) throw new Error();

    this.eventBus.subscribe(InfoUpdatedEvent.Name, (event) => {
      const infos = this.state.infos.data;
      if (infos === null) return;

      const updatedEvent = event as InfoUpdatedEvent;
      const updatedInfo = updatedEvent.info;

      const newInfos = infos.map((info) => (info.id === updatedInfo.id ? updatedInfo : info));
      this.setState({
        ...this.state,
        infos: Resource.success(newInfos),
      });
    });
  }

  state = {
    infos: Resource.idle<Info[]>(),
  };

  async load() {
    this.setState({
      ...this.state,
      infos: Resource.loading(),
    });

    try {
      const infos = await this.repository.getInfoByIds(this.infoIds);

      this.setState({
        ...this.state,
        infos: Resource.success(infos),
      });
    } catch (e) {
      logger.logError(e);

      this.setState({
        ...this.state,
        infos: Resource.error('Informationen konnten nicht geladen werden.'),
      });
    }
  }
}
