import { Languages } from './Park';

export type UserInfo = {};

export type LanguageSpecificInfos = {
  shortDescription: string;
  fullDescription: string;
};

export enum InfoActionType {
  File = 'file',
}

export type InfoAction = {
  id: string;
  type: InfoActionType;
  name: string;
  urls?: Partial<Record<Languages, string>>;
  label: Partial<Record<Languages, string>>;
};

export type Info = {
  id: string;
  //userId
  createdBy: string;
  infoType: InfoType;
  created: Date;
  position: GpsPosition;
  lastModified: Date | null;
  title: string;
  titleImageUrl: string | null;
  tags: string[];
  completed: boolean;
  content: Record<string, LanguageSpecificInfos>;
  deleted: boolean;
  deletedBy: string | null;
  deletedAt: Date | null;
  parkId: string;
  hideImageAuthor: boolean;
  actions: InfoAction[];
  imageAuthor?: string | null;
};

export type GpsPosition = {
  lat: number;
  lng: number;
};

export type InfoPreview = {
  createdBy: UserInfo;
  infoId: string;
  title: string;
  infoType: InfoType;
  createdAt: Date;
  titleImageUrl: string | null;
};

//don't change order of entries when adding new types
export enum InfoType {
  Stature,
  Monument,
  Fountain,
  Building,
  Flower,
  Tree,
  Shop,
  Toilet,
  Other,
  ParkingLot,
  Area,
  Bin,
  Attraction,
}

const InfoTypes: InfoType[] = [
  InfoType.Stature,
  InfoType.Monument,
  InfoType.Fountain,
  InfoType.Building,
  InfoType.Flower,
  InfoType.Tree,
  InfoType.Shop,
  InfoType.Toilet,
  InfoType.ParkingLot,
  InfoType.Area,
  InfoType.Other,
  InfoType.Bin,
  InfoType.Attraction,
];

export { InfoTypes };
export function mapToEnum(value: string): InfoType {
  if (value === 'Statue') return InfoType.Stature;
  else if (value === 'Monument') return InfoType.Monument;
  else if (value === 'Wasserspiel') return InfoType.Fountain;
  else if (value === 'Gebäude') return InfoType.Building;
  else if (value === 'Blume') return InfoType.Flower;
  else if (value === 'Baum') return InfoType.Tree;
  else if (value === 'Geschäft') return InfoType.Shop;
  else if (value === 'WC') return InfoType.Toilet;
  else if (value === 'Bereich') return InfoType.Area;
  else if (value === 'Parkplatz') return InfoType.ParkingLot;
  else if (value === 'Mülleimer') return InfoType.Bin;
  else if (value === 'Attraktion') return InfoType.Attraction;
  else return InfoType.Other;
}

export function mapToString(value: InfoType): string {
  if (value === InfoType.Stature) return 'Statue';
  else if (value === InfoType.Monument) return 'Monument';
  else if (value === InfoType.Fountain) return 'Wasserspiel';
  else if (value === InfoType.Building) return 'Gebäude';
  else if (value === InfoType.Flower) return 'Blume';
  else if (value === InfoType.Tree) return 'Baum';
  else if (value === InfoType.Shop) return 'Geschäft';
  else if (value === InfoType.Toilet) return 'WC';
  else if (value === InfoType.Area) return 'Bereich';
  else if (value === InfoType.ParkingLot) return 'Parkplatz';
  else if (value === InfoType.Bin) return 'Mülleimer';
  else if (value === InfoType.Attraction) return 'Attraktion';
  else return 'Sonstiges';
}

export function mapToIcon(value: InfoType): string {
  if (value === InfoType.Stature) return 'face';
  else if (value === InfoType.Monument) return 'perm_identity';
  else if (value === InfoType.Fountain) return 'waves';
  else if (value === InfoType.Building) return 'business';
  else if (value === InfoType.Flower) return 'local_florist';
  else if (value === InfoType.Tree) return 'eco';
  else if (value === InfoType.Shop) return 'store';
  else if (value === InfoType.Toilet) return 'wc';
  else if (value === InfoType.ParkingLot) return 'directions_car';
  else if (value === InfoType.Area) return 'terrain';
  else if (value === InfoType.Bin) return 'delete';
  else if (value === InfoType.Attraction) return 'tag_faces';
  else return 'more';
}

const mapPathPrefix = '/map/';
export function mapToMapIcon(value: InfoType): string {
  if (value === InfoType.Stature) return `${mapPathPrefix}stature.png`;
  else if (value === InfoType.Monument) return `${mapPathPrefix}monument.png`;
  else if (value === InfoType.Fountain) return `${mapPathPrefix}fountain.png`;
  else if (value === InfoType.Building) return `${mapPathPrefix}building.png`;
  else if (value === InfoType.Flower) return `${mapPathPrefix}flower.png`;
  else if (value === InfoType.Tree) return `${mapPathPrefix}tree.png`;
  else if (value === InfoType.Shop) return `${mapPathPrefix}store.png`;
  else if (value === InfoType.Toilet) return `${mapPathPrefix}wc.png`;
  else if (value === InfoType.ParkingLot) return `${mapPathPrefix}direction_car.png`;
  else if (value === InfoType.Area) return `${mapPathPrefix}terrain.png`;
  else if (value === InfoType.Bin) return `${mapPathPrefix}delete.png`;
  else if (value === InfoType.Attraction) return `${mapPathPrefix}attraction.png`;
  else return `${mapPathPrefix}other.png`;
}
