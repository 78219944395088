import { CircularProgress } from '@material-ui/core';
import { Park } from 'data/entity/Park';
import { AllParksContainer } from 'model/parks/All';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { LocalizedTable } from 'ui/components/Table';
import { Subscribe } from 'unstated-typescript';

export function ParkOverviewPage() {
  return (
    <div>
      <ParkTable />
    </div>
  );
}

function ParkTable() {
  const history = useHistory();

  return (
    <Subscribe to={[AllParksContainer]}>
      {(container) => {
        const { state } = container;
        const { isLoading, isIdle, hasError, message, hasData } = state.parks;

        if (isIdle) {
          container.load();
        }
        if (hasError) return <p>{message}</p>;
        if (isLoading || isIdle) return <CircularProgress />;

        const showDetails = (park: Park) => {
          history.push('/parks/' + park.id);
        };

        const buildLink = (park: Park) => {
          return '/parks/' + park.id;
        };

        const parks = state.parks.data ?? [];
        return (
          <LocalizedTable
            tableId="parks"
            entityname="Park"
            editRow={(park) => {
              showDetails(park);
            }}
            buildLink={buildLink}
            title="Parks"
            columns={[
              {
                title: 'Name',
                render: (park: Park) => {
                  return `${park.displayName}`;
                },
              },
              {
                title: 'Status',
                render: (park) => (park.released ? 'veröffentlicht' : 'privat'),
              },
            ]}
            data={parks}
          />
        );
      }}
    </Subscribe>
  );
}
