import { InfoMembership, UserMembership } from 'data/entity/Membership';
import { MembershipRelations, MembershipRepository } from 'data/repositories/Membership';
import { Resource } from 'data/Resource';
import { EntityAllState, EntityAllStateContainer } from 'model/base';
import { EventBus } from 'services/event/bus';
import logger from 'services/Logging';

export type ParkMemberState = EntityAllState<UserMembership> & {
  infoMemberships: Resource<InfoMembership[]>;
};

export class ParkMemberContainer extends EntityAllStateContainer<UserMembership, ParkMemberState> {
  protected initialState(): ParkMemberState {
    return { entities: Resource.idle(), infoMemberships: Resource.idle() };
  }

  public constructor(
    private repository: MembershipRepository,
    eventBus: EventBus,
    private parkId: string,
  ) {
    super(eventBus);
  }

  protected executeFind(): Promise<UserMembership[]> {
    return this.repository.getParkMember(this.parkId);
  }

  public async loadInfoMemberships() {
    this.setState({
      ...this.state,
      infoMemberships: Resource.loading(),
    });

    try {
      const memberships = await this.repository.getParkInfoMemberships(this.parkId, [
        MembershipRelations.Info,
        MembershipRelations.User,
      ]);
      this.setState({
        ...this.state,
        infoMemberships: Resource.success(memberships),
      });
    } catch (e) {
      logger.logError(e);
      this.setState({
        ...this.state,
        infoMemberships: Resource.error(e),
      });
    }
  }
}
