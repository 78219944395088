import React from 'react';
import { Route, Router, Switch } from 'react-router-dom';
import { Provider } from 'unstated-typescript';
import './App.css';
import { UserContainer } from './model/LoginState';
import {
  AuthenticationService,
  getActiveParkContainer,
  getAllEditorsContainer,
  getAllParksContainer,
  getMembershipRepository,
} from './Provider';
import { RouteWrapper as AuthRoute } from './Router';
import Firebase from './services/Firebase';
import history from './services/History';
import { StatedLoginForm } from './ui/components/auth/Login';
import { Dashboard } from './ui/pages/Dashboard';
import Routes from './ui/pages/Routes';

new Firebase();

export const userContainer = new UserContainer(AuthenticationService(), getMembershipRepository());

function App(): JSX.Element {
  const parkContainer = getAllParksContainer();
  const activeParkContainer = getActiveParkContainer();
  const editorsContainer = getAllEditorsContainer();

  return (
    <Provider inject={[userContainer, activeParkContainer, parkContainer, editorsContainer]}>
      <Router history={history}>
        <div className="App" style={{ height: '100%' }}>
          <Switch>
            <AuthRoute exact path="/" component={Dashboard} isPrivate />
            {Routes.map((r) => r)}
            <Route exact path="/login" component={StatedLoginForm} />
          </Switch>
        </div>
      </Router>
    </Provider>
  );
}

export default App;
