import { GpsPosition, Languages, Park } from 'data/entity/Park';
import firebase from 'firebase';
import {
  CreateParkVariables,
  ParkRepository,
  UpdateParkVariables,
} from '../../data/repositories/Park';
import { MembershipDto } from './dto';
import { membershipCollection } from './shared';

type ParkDto = {
  appOrder: number;
  carDestination: GpsPosition | null;
  displayName: string;
  history: Record<string, string>;
  lat: number;
  lng: number;
  previews: Record<string, string>;
  released: boolean;
  storeFrontId: string | null;
  titleImageUrl?: string | null;
  transitStop: string | null;
};

function removeUndefined(obj: Record<string, any | undefined>): Record<string, any> {
  return Object.keys(obj).reduce((acc, key) => {
    const val = obj[key];
    if (val === undefined) return acc;

    return {
      ...acc,
      [key]: val,
    };
  }, {});
}

export class FirestoreParkRepository implements ParkRepository {
  public constructor(private firestore: firebase.firestore.Firestore) {}

  async update(vars: UpdateParkVariables): Promise<Park> {
    const parkData: Omit<ParkDto, 'released' | 'storeFrontId'> = {
      appOrder: vars.appOrder,
      carDestination: vars.carDestination ?? null,
      displayName: vars.displayName,
      titleImageUrl: vars.titleImageUrl,
      lat: vars.location.lat,
      lng: vars.location.lng,
      previews: vars.previews,
      history: vars.history as Record<Languages, string>,
      transitStop: vars.transitStop ?? null,
    };
    const doc = this.firestore.collection('locations').doc(vars.id);
    await doc.update(parkData);
    const park = await this.findById(vars.id);
    return park!;
  }

  generateId(): string {
    return this.firestore.collection('locations').doc().id;
  }

  async create(vars: CreateParkVariables): Promise<Park> {
    await this.firestore.runTransaction(async (transaction) => {
      const parkDoc = this.firestore.collection('locations').doc(vars.id);
      const parkData: Omit<ParkDto, 'storeFrontId'> = {
        appOrder: vars.appOrder,
        carDestination: vars.carDestination ?? null,
        displayName: vars.displayName,
        titleImageUrl: vars.titleImageUrl,
        lat: vars.location.lat,
        lng: vars.location.lng,
        released: false,
        previews: vars.previews,
        history: vars.history as Record<Languages, string>,
        transitStop: vars.transitStop ?? null,
      };

      await transaction.set(parkDoc, removeUndefined(parkData));
      const membershipDoc = this.firestore.collection(membershipCollection).doc();
      const membershipData: MembershipDto = {
        userId: vars.userId,
        parkId: vars.id,
        read: true,
        write: true,
        admin: true,
      };

      await transaction.set(membershipDoc, membershipData);
    });

    const park = await this.findById(vars.id);
    return park!;
  }

  private parseDoc(document: firebase.firestore.DocumentSnapshot): Park | undefined {
    if (!document.exists) return undefined;
    const data = document.data()! as ParkDto;

    const id = document.id;
    const appOrder = data.appOrder as number;
    const displayName = data.displayName as string;
    const previews = data.previews;
    const released = data.released ?? false;
    const titleImageUrl = data.titleImageUrl ?? null;
    const location = { lat: data.lat, lng: data.lng };

    return {
      appOrder,
      displayName,
      previews,
      released,
      titleImageUrl,
      id,
      history: data.history ?? {},
      carDestination: data.carDestination ?? undefined,
      location,
      transitStop: data.transitStop ?? undefined,
    };
  }

  findById(id: string): Promise<Park | undefined> {
    return this.firestore.collection('locations').doc(id).get().then(this.parseDoc);
  }

  findAll(): Promise<Park[]> {
    return this.firestore
      .collection('locations')
      .get()
      .then((snap) => {
        const { docs: documents } = snap;

        return documents.map(this.parseDoc).filter((park) => park !== undefined) as Park[];
      });
  }
}
