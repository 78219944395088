import { Box, Card, CircularProgress, List, ListItem, ListItemText } from '@material-ui/core';
import React from 'react';
import {
  ActiveParkSelectionBuildArgs,
  ActiveParkSelectionBuilder,
} from 'ui/components/park/ActiveParkBuilder';
import { Center } from 'ui/components/util/Center';

function build(args: ActiveParkSelectionBuildArgs): JSX.Element {
  const parksRes = args.parksContainer.state.parks;
  if (parksRes.hasError) return <p>{parksRes.message}</p>;

  if (parksRes.isLoading) return <CircularProgress />;

  const parks = parksRes.data ?? [];

  const setActivePark = (id) => args.activeParkContainer.setActivePark(id);

  return (
    <Box
      bgcolor="#003022"
      height="100%"
      width="100%"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <Center
        child={
          <Card>
            <Box p={4}>
              <h1>Park auswählen</h1>

              {parks.length === 0 && <p>Du bist noch kein Mitglied einer Parkanlage.</p>}
              {parks.length > 0 && (
                <div>
                  <p>Wähle einen Park aus, um dessen Inhalte einzusehen und zu bearbeiten.</p>
                  <List dense={true}>
                    {parks.map((park) => {
                      return (
                        <ListItem key={park.id} button onClick={() => setActivePark(park.id)}>
                          <ListItemText primary={park.displayName} />
                        </ListItem>
                      );
                    })}
                  </List>
                </div>
              )}
            </Box>
          </Card>
        }
      />
    </Box>
  );
}

export function ActiveParkSelectionPage() {
  return <ActiveParkSelectionBuilder builder={build} />;
}
