import React from 'react';
import { ParkOverviewPage } from './parks/All';
import { RouteWrapper as AuthRoute, AdminRoute, ParkAdminRoute } from './../../Router';
import { ParkDetailPage } from './parks/Detail';
import { InfoOverviewPage } from './infos/Overview';
import { InfoDetails } from './infos/Details';
import { Dashboard } from './Dashboard';
import { MemberOverviewPage } from './member/Overview';
import { MembershipDetailsPage } from './member/Detail';

export default [
  <AuthRoute exact path="/park/:parkId/infos" component={InfoOverviewPage} isPrivate />,
  <AuthRoute
    exact
    path="/infos"
    component={InfoOverviewPage}
    isPrivate
    parkMemberRequired={false}
  />,
  <AuthRoute
    exact
    path="/park/:parkId/infos/:infoId"
    component={InfoDetails}
    isPrivate
    parkMemberRequired={false}
  />,
  <AuthRoute exact path="/park/:parkId/info" component={InfoDetails} isPrivate />,
  <AdminRoute exact path="/park" component={ParkDetailPage} />,
  <AuthRoute exact path="/park/:parkId" component={Dashboard} isPrivate />,
  <AuthRoute exact path="/parks" component={ParkOverviewPage} isPrivate />,
  <AuthRoute exact path="/park/:parkId/details" component={ParkDetailPage} isPrivate />,
  <ParkAdminRoute exact path="/park/:parkId/members" component={MemberOverviewPage} />,
  <ParkAdminRoute exact path="/park/:parkId/membership" component={MembershipDetailsPage} />,
  <ParkAdminRoute
    exact
    path="/park/:parkId/membership/:membershipId"
    component={MembershipDetailsPage}
  />,
];
