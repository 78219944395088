import { isParkMembership, ParkMembership } from 'data/entity/Membership';
import { UserContainer } from 'model/LoginState';
import React from 'react';
import { Subscribe } from 'unstated-typescript';

export type RequiresParkMembershipProps = {
  children?: React.ReactNode;
};
export function RequiresParkMembership(props: RequiresParkMembershipProps) {
  return (
    <Subscribe to={[UserContainer]}>
      {(container) => {
        const hasParkMembership = container.state.memberships.data?.some(isParkMembership) ?? false;

        if (!hasParkMembership) return <div></div>;

        return <>{props.children}</>;
      }}
    </Subscribe>
  );
}

export type ParkMembershipsBuilderProps = {
  children: (memberships: ParkMembership[]) => React.ReactNode;
};

export function ParkMembershipsBuilder(props: ParkMembershipsBuilderProps) {
  return (
    <Subscribe to={[UserContainer]}>
      {(container) => {
        const memberships = container.state.memberships.data?.filter(isParkMembership) ?? [];

        return <>{props.children(memberships)}</>;
      }}
    </Subscribe>
  );
}
