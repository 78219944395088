export enum ResourceState {
  Idle,
  Loading,
  Error,
  Success,
}

export type PageResult<T> = {
  items: T[];
  page: number;
};

export class Resource<T> {
  public data: T | null;
  public state: ResourceState;
  public message?: string;

  constructor(data: T | null, state: ResourceState, message?: string) {
    this.data = data;
    this.state = state;
    this.message = message;
  }

  static success<T>(data: T): Resource<T> {
    return new Resource(data, ResourceState.Success);
  }
  static idle<T>(): Resource<T> {
    return new Resource<T>(null, ResourceState.Idle);
  }

  static loading<T>(): Resource<T> {
    return new Resource<T>(null, ResourceState.Loading);
  }

  static error<T>(error: string): Resource<T> {
    return new Resource<T>(null, ResourceState.Error, error);
  }

  get isLoading(): boolean {
    return this.state === ResourceState.Loading;
  }

  get hasError(): boolean {
    return this.state === ResourceState.Error;
  }

  get hasData(): boolean {
    return this.state === ResourceState.Success;
  }
  get isIdle(): boolean {
    return this.state === ResourceState.Idle;
  }

  public toOperation(): Operation {
    return new Operation(this.state, this.message);
  }
}

export class Operation {
  public state: ResourceState;
  public message?: string;

  constructor(state: ResourceState, message?: string) {
    this.state = state;
    this.message = message;
  }

  static success(): Operation {
    return new Operation(ResourceState.Success);
  }
  static idle(): Operation {
    return new Operation(ResourceState.Idle);
  }

  static loading(): Operation {
    return new Operation(ResourceState.Loading);
  }

  static error(error: string): Operation {
    return new Operation(ResourceState.Error, error);
  }

  get isIdle(): boolean {
    return this.state === ResourceState.Idle;
  }

  get isLoading(): boolean {
    return this.state === ResourceState.Loading;
  }

  get hasError(): boolean {
    return this.state === ResourceState.Error;
  }

  get isFinished(): boolean {
    return this.state === ResourceState.Success;
  }
}
